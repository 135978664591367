import { Box } from '@mui/material';
import { ChatInput } from '../../components/ChatContent/components/ChatInput/ChatInput.component';
import { GreetingMessage } from '../../components/ChatContent/components/BotMessage/components/GreetingMessage/GreetingMessage.component';
import { greetingContainerSx } from './Greeting.style';

export const Greeting = () => {
  return (
    <Box sx={greetingContainerSx}>
      <Box
        sx={{
          overflow: 'scroll',
        }}
      >
        <GreetingMessage />
      </Box>
      <ChatInput />
    </Box>
  );
};
