import Avatar from '@mui/material/Avatar';

export const UserIcon = ({ username, height, width }) => {
  const firstLetter = username ? username[0].toUpperCase() : '?';

  return (
    <Avatar
      style={{
        backgroundColor: '#E3E3E3',
        color: '#2E2E2E',
        height,
        width,
      }}
    >
      {firstLetter}
    </Avatar>
  );
};
