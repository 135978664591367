import React from 'react';
import { Typography, Box } from '@mui/material';
import {
  containerSx,
  botTitleSx,
  mainSx,
  botDescriptionSx,
} from './Intro.style';
import { INTRODUCTION } from './Intro.content';
import { MobileLoginSignupButtons } from './components/MobileLoginSignupButtons/MobileLoginSignupButtons.component';
import { useResponsive } from '../../hooks/useResponsive';

export const Intro = () => {
  const { isMobile } = useResponsive();

  return (
    <Box
      sx={{
        ...mainSx,
      }}
    >
      <Box
        sx={{
          ...containerSx,
          padding: `${isMobile ? '60px 30px' : '0 90px'}`,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.69rem' }}
          >
            <Typography variant="h1" align="center" color="secondary">
              SuperPrime.Bot
            </Typography>
            <Typography variant="subtitle1" align="center" color="secondary">
              AI-driven property search
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: `${isMobile ? '28px' : '32px'}`,
              ...botTitleSx,
            }}
          >
            {INTRODUCTION.chatbotTitle}
          </Typography>
          <Typography
            sx={{
              fontSize: `${isMobile ? '16px' : '18px'}`,
              ...botDescriptionSx,
            }}
          >
            {INTRODUCTION.chatbotDescription}
          </Typography>
        </Box>
        {isMobile && <MobileLoginSignupButtons />}
      </Box>
    </Box>
  );
};
