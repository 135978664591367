import { Box, CircularProgress, Typography } from '@mui/material';
import { ChatInput } from './components/ChatInput/ChatInput.component';
import { BotMessage } from './components/BotMessage/BotMessage.component';
import { UserMessage } from './components/UserMessage/UserMessage.component';
import {
  ChatContentSx,
  loaderSx,
  messagesContainerSx,
} from './ChatContent.style';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useToast } from '../../hooks/UseToast';
import { chatService } from '../../api/ChatService';
import { GreetingMessage } from './components/BotMessage/components/GreetingMessage/GreetingMessage.component';
import loader from './../../assets/typing-indicator.gif';

export const ChatContent = () => {
  const { conversationId } = useParams();
  const { showErrorToast } = useToast();
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const messagesEndRef = useRef(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const { data: allMessages, isLoading } = useQuery(
    ['messages', conversationId],
    () => chatService.fetchMessages(conversationId),
    {
      refetchInterval: shouldRefetch ? 1000 : false,
      onError: (error) => {
        showErrorToast();
      },
    }
  );
  const isLoaderShown =
    allMessages && allMessages.length && shouldRefetch ? true : false;
  const isGreetingShown = allMessages && allMessages.length === 0;

  useEffect(() => {
    if (allMessages && allMessages.length > 0) {
      const lastMessage = allMessages[allMessages.length - 1];

      setShouldRefetch(!lastMessage.is_ai_response);
    }
  }, [allMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  useEffect(() => {
    let intervalId;
    if (isLoaderShown) {
      intervalId = setInterval(() => {
        setIsLoaderVisible((prev) => !prev);
      }, 4010);
    } else {
      setIsLoaderVisible(false);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isLoaderShown]);

  useEffect(() => {
    if (isLoaderVisible) {
      scrollToBottom();
    }
  }, [isLoaderVisible]);

  return (
    <Box
      sx={{
        ...ChatContentSx,
        position: 'relative',
        gap: '24px',
      }}
    >
      <Box sx={messagesContainerSx}>
        {isLoading && !isLoaderShown && (
          <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {isLoaderShown && isLoaderVisible && (
          <Box sx={loaderSx}>
            <img width="50" src={loader} alt="loader" />
          </Box>
        )}
        {isGreetingShown && <GreetingMessage />}

        {!isLoading &&
          allMessages &&
          allMessages.map((message, index) =>
            message.is_ai_response ? (
              <BotMessage key={message.id} message={message} />
            ) : (
              <UserMessage key={message.id} message={message.content} />
            )
          )}

        {isLoaderVisible && <Box sx={{ padding: '1px 0' }}></Box>}
        <div ref={messagesEndRef} />
      </Box>
      <ChatInput isResponsing={isLoaderShown} />
    </Box>
  );
};
