import React, { createContext, useState, useContext } from 'react';

const ProfileContext = createContext(null);

const initialValue = {
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
};

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(initialValue);

  return (
    <ProfileContext.Provider value={{ profile, setProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
