export const ChatInputSx = {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #ccc',
  borderRadius: '26px',
  backgroundColor: 'white',
  padding: '5px',
  paddingLeft: '15px',
  width: '100%',
};

export const sendBtnSx = {
  paddingLeft: '16px',
  border: 'none',
  backgroundColor: 'transparent',
};
