import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { paragraphSx, titleSx, underlineSx } from './PrivacyPolicy.style';
import { MainLabel } from '../../../MainLabel/MainLabel.component';

export const PrivacyPolicy = ({ open, handleClose }) => {
  return (
    <Dialog open={open} fullWidth maxWidth={'lg'}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Box>
            <MainLabel />
          </Box>
          Privacy policy
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={titleSx}>Introduction</Typography>
        <Typography sx={paragraphSx}>
          SuperPrime.Bot is committed to protecting the privacy and security of
          our users’ personal information. This Privacy Policy outlines our
          practices concerning the collection, use, and disclosure of personal
          data when you use our service and the choices you have associated with
          that data.
        </Typography>
        <Typography sx={titleSx}>
          Compliance with GDPR and UK Privacy Laws
        </Typography>
        <Typography sx={paragraphSx}>
          We adhere strictly to the General Data Protection Regulation (GDPR)
          and UK privacy regulations in all our data handling practices.
        </Typography>
        <Typography sx={titleSx}>Information Collection and Use</Typography>
        <Typography sx={paragraphSx}>
          We collect several different types of information for various purposes
          to provide and improve our service to you.
        </Typography>
        <Typography sx={titleSx}>Types of Data Collected</Typography>
        <Typography sx={underlineSx}>Personal Data</Typography>
        <Typography sx={paragraphSx}>
          While using our service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). This may include, but is not limited
          to:
          <br />• Email address <br /> • First name and last name <br /> • Phone
          number <br /> • Address, State, Province, ZIP/Postal code, City <br />
          • Cookies and Usage
        </Typography>
        <Typography sx={underlineSx}>Data Usage</Typography>
        <Typography sx={paragraphSx}>
          Data We may also collect information on how the service is accessed
          and used (“Usage Data”). This Usage Data may include information such
          as your computer’s Internet Protocol address (e.g., IP address),
          browser type, browser version, the pages of our service that you
          visit, the time and date of your visit, the time spent on those pages,
          unique device identifiers, and other diagnostic data.
        </Typography>
        <Typography sx={titleSx}>Use of Data</Typography>
        <Typography sx={paragraphSx}>
          SuperPrime.Bot uses the collected data for various purposes: <br /> •
          To provide and maintain our service <br /> • To notify you about
          changes to our service <br />
          • To allow you to participate in interactive features of our service
          when you choose to do so <br /> • To provide customer support <br /> •
          To gather analysis or valuable information so that we can improve our
          service <br /> • To monitor the usage of our service <br /> • To
          detect, prevent, and address technical issues
        </Typography>
        <Typography sx={titleSx}>Transfer of Data</Typography>
        <Typography sx={paragraphSx}>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country, or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </Typography>
        <Typography sx={titleSx}>Disclosure of Data</Typography>
        <Typography sx={underlineSx}> Legal Requirements</Typography>
        <Typography sx={paragraphSx}>
          SuperPrime.Bot may disclose your Personal Data in the good faith
          belief that such action is necessary to: <br /> • Comply with a legal
          obligation <br /> • Protect and defend the rights or property of
          SuperPrime.Bot <br /> • Prevent or investigate possible wrongdoing in
          connection with the service <br /> • Protect the personal safety of
          users of the service or the public <br /> • Protect against legal
          liability
        </Typography>
        <Typography sx={underlineSx}>Estate Agents</Typography>
        <Typography sx={paragraphSx}>
          If you decide to contact an estate agent through our service, we will
          provide your details to the estate agent to facilitate your property
          search.
        </Typography>
        <Typography sx={underlineSx}>
          Service Providers and SuperPrime.Bot Group Companies
        </Typography>
        <Typography sx={paragraphSx}>
          Your data may be shared with service providers we use to help us
          deliver our services and within our group companies who may all
          provide different parts of the service
        </Typography>
        <Typography sx={titleSx}>Security of Data</Typography>
        <Typography sx={paragraphSx}>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </Typography>
        <Typography sx={titleSx}>
          Your Data Protection Rights Under GDPR
        </Typography>
        <Typography sx={paragraphSx}>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. SuperPrime.Bot aims to take reasonable
          steps to allow you to correct, amend, delete, or limit the use of your
          Personal Data.
        </Typography>
        <Typography sx={titleSx}>Changes to This Privacy Policy</Typography>
        <Typography>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
