import { axiosInstance } from './api';

class ChatService {
  async fetchConversations() {
    const { data } = await axiosInstance.get('conversations/');
    return data;
  }

  async createNewConversation() {
    const { data } = await axiosInstance.post('conversations/');
    return data;
  }

  async fetchMessages(id) {
    const { data } = await axiosInstance.get(`conversations/${id}/messages/`);
    return data;
  }

  async sendMessage(id, message) {
    const { data } = await axiosInstance.post(
      `conversations/${id}/messages/`,
      message
    );
    return data;
  }

  async transcribeAudio(blob) {
    const { data } = await axiosInstance.post(`/audio-transcribe/`, blob);
    return data;
  }

  async sendFeedback(conversationId, messageRatingId, ratingData) {
    const { data } = await axiosInstance.patch(
      `conversations/${conversationId}/message-rating/${messageRatingId}/`,
      ratingData
    );
    return data;
  }
}

export const chatService = new ChatService();
