import { Route, Routes, useNavigate } from 'react-router-dom';
import { MainContentLayout } from '../../layouts/MainContentLayout/MainContent.layout';
import { mainLayoutRoutesConfig } from './MainLayoutRoutesConfig';
import { SignUp } from '../../pages/SignUp/SignUp.page';
import { Login } from '../../pages/Login/Login.page';
import { ProtectedRoute } from './ProtectedRoute';
import { PasswordRecovery } from '../PasswordRecovery/PasswordRecovery.component';
import { SetNewPassword } from '../SetNewPassword/SetNewPassword.component';
import { CheckEmail } from '../CheckEmail/CheckEmail.component';
import { EmailConfirmation } from '../EmailConfirmation/EmailConfirmation';
import { useEffect } from 'react';

export const LayoutResolver = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleUnauthorizedEvent = () => {
      localStorage.removeItem('token');
      navigate('/login');
    };

    window.addEventListener('unauthorized', handleUnauthorizedEvent);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorizedEvent);
    };
  }, [navigate]);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/password-reset/:uid/:token" element={<SetNewPassword />} />
      <Route
        path="/confirm-email/:uid/:token"
        element={<EmailConfirmation />}
      />
      <Route
        path="/check-email"
        element={<CheckEmail action={'reset your password'} />}
      />
      <Route
        path="/email-confirm"
        element={<CheckEmail action={'confirm your email'} />}
      />

      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <MainContentLayout />
          </ProtectedRoute>
        }
      >
        {mainLayoutRoutesConfig.map((route) => (
          <Route
            key={route.path | 'index'}
            path={route.path}
            element={route.element}
          />
        ))}
      </Route>
      <Route path="/password-recovery" element={<PasswordRecovery />} />
    </Routes>
  );
};
