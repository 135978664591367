import React from 'react';
import { Container, Box } from '@mui/material';
import { HeaderWithLogo } from '../HeaderWithLogo/HeaderWithLogo.component';
import { AuthContainer } from '../AuthContainer/AuthContainer.component';
import { useResponsive } from '../../hooks/useResponsive';
import { PasswordRecoveryForm } from './components/PasswordRecoveryForm/PasswordRecoveryForm.component';
import { containerSx } from '../../pages/SignUp/components/SignUpFormWrapper/SignUpForm.style';

export const PasswordRecovery = () => {
  const { isMobile } = useResponsive();

  return (
    <AuthContainer>
      <Box
        sx={{
          padding: '0px 128px',
          display: `${isMobile ? 'none' : 'grid'}`,
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Container sx={containerSx}>
          <HeaderWithLogo title=" Password recovery" />
          <PasswordRecoveryForm />
        </Container>
      </Box>
    </AuthContainer>
  );
};
