export const helpCenterSx = {
  cursor: 'pointer',
  position: 'fixed',
  bottom: '15px',
  '@media (max-height: 896px)': {
    bottom: '10px',
  },
  '@media (max-height: 731px)': {
    bottom: '8px',
  },
};
