import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

export const MessageContent = ({ content }) => {
  const cleanedContent = content
    .replace(/\n{2,}/g, '\n')
    .replace(/\s{2,}/g, ' ')
    .trim();
  return (
    <Box>
      <Typography sx={{ whiteSpace: 'pre-wrap' }}>
        <ReactMarkdown breaks={true}>{cleanedContent}</ReactMarkdown>
      </Typography>
    </Box>
  );
};
