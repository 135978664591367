import { Box, Typography } from '@mui/material';
import { UserIcon } from '../../../UserIcon/UserIcon.component';
import { useResponsive } from '../../../../hooks/useResponsive';
import { useProfile } from '../../../../context/profileContext';

export const UserMessage = ({ message }) => {
  const { isMobile } = useResponsive();
  const { profile } = useProfile();
  const first_name = profile ? profile.first_name : '?';

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        padding: '0 16px',
      }}
    >
      <UserIcon
        username={first_name}
        width={isMobile ? '32px' : '56px'}
        height={isMobile ? '32px' : '56px'}
      />

      <Typography
        sx={{
          paddingTop: `${isMobile ? '5px' : '15px'}`,
          color: '#000000',
          wordWrap: 'break-word',
          paddingLeft: '24px',
          whiteSpace: 'normal',
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};
