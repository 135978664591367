import { Typography } from '@mui/material';
import { PropertyValueContainer } from '../PropertyValueContainer/PropertyValueContainer.component';

export const PropertyDescription = ({ description }) => {
  return (
    <PropertyValueContainer title="Description">
      <Typography>{description}</Typography>
    </PropertyValueContainer>
  );
};
