import { Box } from '@mui/material';
import logo from '../../../../assets/icons/logo.png';
import { containerMessageSx } from './BotMessage.style';
import { Feedback } from './components/Feedback/Feedback.component';
import { MessageContent } from './components/MessageContent/MessageContent.component';
import { useResponsive } from '../../../../hooks/useResponsive';
import { FeedbackForm } from './components/FeedbackForm/FeedbackForm.component';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { chatService } from '../../../../api/ChatService';
import { Property } from './components/Property/Property.component';
import { useToast } from '../../../../hooks/UseToast';
import { isJSON } from '../../../../utils/utils';
import { mergeAndUpdateDescriptions } from './BotMessage.utils';

export const BotMessage = ({ message }) => {
  const { isMobile } = useResponsive();
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);
  const iconSize = isMobile ? '32px' : '56px';
  const { conversationId } = useParams();
  let propertiesFromAI, messageContentFromAI;

  if (isJSON(message.content)) {
    const parsedMessage = JSON.parse(message.content);
    propertiesFromAI = parsedMessage.properties;
    messageContentFromAI = parsedMessage.intro;
  }
  const { showSuccessToast, showErrorToast } = useToast();
  const messageContent = isJSON(message.content)
    ? messageContentFromAI
    : message.content;
  const properties = isJSON(message.content)
    ? mergeAndUpdateDescriptions(propertiesFromAI, message.properties)
    : message.properties;

  const handleFeedbackClick = (rating) => {
    setIsFeedbackFormOpen(true);
    chatService
      .sendFeedback(conversationId, message.message_rating.id, {
        rating,
      })
      .then(() => {
        showSuccessToast('Feedback has been sent');
      })
      .catch((error) => showErrorToast());
  };

  const handleFeedbackFormClose = () => setIsFeedbackFormOpen(false);
  return (
    <Box sx={containerMessageSx}>
      <Box>
        <img
          width={`${iconSize}`}
          height={`${iconSize}`}
          src={logo}
          alt="logo"
        />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box
          display="grid"
          sx={{ gridTemplateColumns: `${isMobile ? '1fr' : '1fr 84px'}` }}
        >
          <MessageContent content={messageContent} />
          <Feedback handleFeedbackClick={handleFeedbackClick} />
          <Box>
            {properties &&
              properties.map((property) => (
                <Property key={property.id} property={property} />
              ))}
          </Box>
        </Box>
        {isFeedbackFormOpen && (
          <FeedbackForm
            messageRatingId={message.message_rating.id}
            handleClose={handleFeedbackFormClose}
          />
        )}
      </Box>
    </Box>
  );
};
