import { Box } from '@mui/material';
import { centeredFlexSx } from '../../layouts/MainContentLayout/MainContent.style';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export const FavoriteIconBtn = ({ clickHandler, isFavorite }) => {
  return (
    <Box
      onClick={clickHandler}
      sx={{
        ...centeredFlexSx,
        borderRadius: '50%',
        padding: '8px',
        color: '#6B6B6B',
        '&:hover': {
          backgroundColor: '#E3E3E3',
          cursor: 'pointer',
          '& .MuiSvgIcon-root': {
            color: '#3490DC',
          },
        },
        backgroundColor: isFavorite ? '#E3E3E3' : 'transparent',
        '& .MuiSvgIcon-root': {
          color: isFavorite ? '#3490DC' : 'inherit',
        },
        cursor: 'pointer',
      }}
    >
      <FavoriteBorderIcon />
    </Box>
  );
};
