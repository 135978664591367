import React, { createContext, useState, useContext } from 'react';

const FavoritesContext = createContext(null);

const initialValue = [];

export const FavoritesProvider = ({ children }) => {
  const [favProperties, setFavProperties] = useState(initialValue);

  return (
    <FavoritesContext.Provider value={{ favProperties, setFavProperties }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavoritesList = () => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error('useFavoritesList must be used within a FavoritesProvider');
  }
  return context;
};
