import { Box, Button, IconButton, Typography } from '@mui/material';
import { WhiteBackgroundTextField } from '../../../../../WhiteBackgroundTextField/WhiteBackgroundTextField';
import { useFormik } from 'formik';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { containerSx, sendBtnSx } from './FeedbackForm.style';
import { initialValues, validationSchema } from './FeedbackForm.utils';
import { useToast } from '../../../../../../hooks/UseToast';
import { useParams } from 'react-router-dom';
import { chatService } from '../../../../../../api/ChatService';

export const FeedbackForm = ({ handleClose, messageRatingId, rating }) => {
  const { isMobile } = useResponsive();
  const [isInputOpen, setIsInputOpen] = useState(true);
  const { showErrorToast } = useToast();
  const { conversationId } = useParams();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsInputOpen(false);
      chatService
        .sendFeedback(conversationId, messageRatingId, {
          ...formik.values,
        })
        .catch((error) => showErrorToast());
    },
  });

  return (
    <Box sx={containerSx}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>
          {isInputOpen
            ? 'Would you like to share your comments? (Optional)'
            : 'Thank you for your feedback!'}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {isInputOpen && (
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box
            display={`${isMobile ? 'block' : 'grid'}`}
            sx={{ gridTemplateColumns: '50% 60px' }}
          >
            <WhiteBackgroundTextField
              required
              id="feedback"
              label="Add review"
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
              autoFocus
            />
            <Button type="submit" sx={sendBtnSx}>
              Send
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};
