import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '../../hooks/useResponsive';
import { SidebarList } from './components/SidebarList/SidebarList.component';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { useQuery } from 'react-query';
import { useToast } from '../../hooks/UseToast';
import { chatService } from '../../api/ChatService';
import { propertyService } from '../../api/PropertyService';
import { useFavoritesList } from '../../context/favoritesContext';
import { useEffect } from 'react';
import { useConversationsList } from '../../context/conversationsContext';
import { helpCenterSx } from './Sidebar.style';

export const Sidebar = ({ closeDrawerHandler }) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const { setFavProperties } = useFavoritesList();
  const { setConversations } = useConversationsList();
  const { data: conversationsData, refetch: refetchConversations } = useQuery(
    'conversations',
    chatService.fetchConversations,
    {
      onError: (error) => {
        showErrorToast();
      },
    }
  );

  const { data: favoritesData } = useQuery(
    'favorites',
    propertyService.fetchFavorites,
    {
      onError: (error) => {
        showErrorToast();
      },
    }
  );

  useEffect(() => {
    favoritesData && setFavProperties(favoritesData.results);
    conversationsData && setConversations(conversationsData.results);
  }, [favoritesData, setFavProperties, conversationsData, setConversations]);

  const selectRecentHandler = (id) => {
    navigate(`/conversation/${id}`);
    isMobile && closeDrawerHandler();
  };

  const selectFavoritesHandler = (id) => {
    navigate(`/favorite/${id}`);
    isMobile && closeDrawerHandler();
  };

  const createNewConversationHandler = () => {
    chatService
      .createNewConversation()
      .then((res) => navigate(`conversation/${res.id}`))
      .catch((error) => showErrorToast());
    refetchConversations();
    isMobile && closeDrawerHandler();
  };

  const openHelpHandler = () =>
    window.open(
      'https://romantic-beech-3d6.notion.site/HomeHapp-AI-3f069d1d7d89441fb2bbc91c3a5f7e40',
      '_blank'
    );

  return (
    <Box
      sx={{
        padding: '24px 20px',
        boxSizing: 'border-box',
      }}
    >
      <Button
        onClick={createNewConversationHandler}
        sx={{
          width: '227px',
          color: '#1A365D',
          '&:hover': { backgroundColor: '#FF9407' },
        }}
        variant="contained"
        color="secondary"
      >
        new search
      </Button>

      <Box display="flex" flexDirection="column" justifyContent="space-around">
        <SidebarList
          selectHandler={selectRecentHandler}
          title="Current searches"
          items={conversationsData && conversationsData.results}
        />
        <SidebarList
          selectHandler={selectFavoritesHandler}
          title="Favorites"
          items={favoritesData && favoritesData.results}
          isFavorite={true}
        />
        {isMobile && (
          <Box
            onClick={openHelpHandler}
            sx={helpCenterSx}
            display="flex"
            alignItems={'center'}
            color="#6B6B6B"
          >
            <HelpOutlineRoundedIcon />
            <Typography>Help Centre & Tutorial </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
