import * as Yup from 'yup';

export const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Required'),
});

export const initialValues = {
  email: '',
  password: '',
};
