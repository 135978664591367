import { Box } from '@mui/material';

export const SidebarListItem = ({ children }) => {
  return (
    <Box
      sx={{
        width: '227px',
        borderRadius: '8px',
        padding: '10px 16px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:hover': {
          backgroundColor: '#F7F7F7',
          cursor: 'pointer',
          color: '#2E2E2E',
        },
      }}
    >
      {children}
    </Box>
  );
};
