import { Box, Button, Container, Typography } from '@mui/material';
import { HeaderWithLogo } from '../../../HeaderWithLogo/HeaderWithLogo.component';
import { useResponsive } from '../../../../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';

export const SettingsCheckEmail = () => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const email = localStorage.getItem('email');

  const backHandler = () => {
    localStorage.removeItem('email');
    navigate('/profile-settings');
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: `${isMobile ? 'none' : 'grid'}`,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container sx={{ padding: '60px', width: '540px' }}>
        <HeaderWithLogo title="Check Email" />
        <Typography textAlign="center" sx={{ marginBottom: '32px' }}>
          Please check the email address
          <Typography
            component="span"
            sx={{ fontWeight: '600', margin: '0 5px' }}
          >
            {email}
          </Typography>
          for instructions to reset your password.
        </Typography>
        <Button
          onClick={backHandler}
          fullWidth
          variant="contained"
          color="secondary"
          size="large"
        >
          Back
        </Button>
      </Container>
    </Box>
  );
};
