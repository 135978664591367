export const mainSx = {
  display: 'grid',
  gap: '1.5rem',
  backgroundImage: 'linear-gradient(135deg, #003366 0%, #004080 100%)',
  alignItems: 'center',
};

export const dividerSx = {
  margin: '36px 0px',
  backgroundColor: '#FFFFFF',
};

export const containerSx = {
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
};

export const botTitleSx = {
  fontWeight: 600,
  maxWidth: '541px',
  textAlign: 'center',
  lineHeight: '140%',
  margin: '0 auto',
  color: '#FFF9FF',
};

export const botDescriptionSx = {
  fontWeight: 400,
  maxWidth: '561px',
  textAlign: 'center',
  margin: '0 auto',
  color: '#DDD',
};
