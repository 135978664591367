export const INTRODUCTION = {
  chatbotTitle: 'Discover London`s finest homes with intelligent precision',
  chatbotDescription:
    "SuperPrime.Bot combines cutting-edge AI with unparalleled luxury real estate expertise to find your perfect property in London's most prestigious neighborhoods.",
};

export const PLATES_CONTENT = {
  plate1:
    'SearchBot will instantly cherry \n pick the perfect matches, summarize the key details, and direct you to the listings.',
  plate2:
    'Currently focused on prime central London luxury homes over £1m, our AI makes finding your ideal property easy. ',
};
