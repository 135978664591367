export const ChatContentSx = {
  height: '100%',
  display: 'grid',
  gridTemplateRows: '1fr auto',
};

export const loaderSx = {
  position: 'absolute',
  left: '15px',
  bottom: '63px',
  borderRadius: '30px',
  backgroundColor: '#EAECEF',
  padding: '5px 8px',
};

export const messagesContainerSx = {
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
};
