import { useNavigate } from 'react-router-dom';
import { useShowPassword } from '../../../../../hooks/FormHooks/useShowPassword.hook';
import { WhiteBackgroundTextField } from '../../../../../components/WhiteBackgroundTextField/WhiteBackgroundTextField';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './LoginForm.utils';
import { Box, IconButton, InputAdornment, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useToast } from '../../../../../hooks/UseToast';
import { authService } from '../../../../../api/AuthService';
import { PrimaryButton } from '../../../../../components/PrimaryButton/PrimaryButton.component';

export const LoginForm = ({ closeModal, handleOpenRecovery }) => {
  const [showPassword, handlePasswordVisibility] = useShowPassword();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      authService
        .login(values)
        .then((res) => {
          if (res.access_token) {
            navigate('/');
          }
        })
        .catch((error) => {
          console.log(error);
          showErrorToast(error.response.data.non_field_errors[0]);
        });

      closeModal && closeModal();
      resetForm();
    },
  });

  const handleForgotPassword = () => {
    handleOpenRecovery ? handleOpenRecovery() : navigate('/password-recovery');
  };

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <WhiteBackgroundTextField
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        autoFocus
      />
      <WhiteBackgroundTextField
        required
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Link
        onClick={handleForgotPassword}
        underline="none"
        variant="body2"
        color="primary"
        py={2}
        sx={{
          fontSize: '1rem',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        Forgot password?
      </Link>
      <Box sx={{ paddingTop: '12px' }}>
        <PrimaryButton
          label="Login"
          disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
        ></PrimaryButton>
      </Box>
    </form>
  );
};
