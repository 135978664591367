export const mergeAndUpdateDescriptions = (
  propertiesFromAI,
  propertiesFromDB
) => {
  let newArray = [];
  let dbMap = new Map(propertiesFromDB.map((obj) => [obj.id, obj]));

  propertiesFromAI &&
    propertiesFromAI.forEach(([description, id]) => {
      const dbObj = dbMap.get(id);
      if (dbObj) {
        newArray.push({
          ...dbObj,
          description,
        });
        dbMap.delete(id);
      }
    });

  dbMap.forEach((obj) => {
    newArray.push({
      ...obj,
      description: obj.description,
    });
  });

  return newArray;
};
