import React, { createContext, useState, useContext } from 'react';

const ConversationsContext = createContext(null);

const initialValue = [];

export const ConversationsProvider = ({ children }) => {
  const [conversations, setConversations] = useState(initialValue);

  return (
    <ConversationsContext.Provider value={{ conversations, setConversations }}>
      {children}
    </ConversationsContext.Provider>
  );
};

export const useConversationsList = () => {
  const context = useContext(ConversationsContext);
  if (!context) {
    throw new Error(
      'useConversationsList must be used within a ConversationsProvider'
    );
  }
  return context;
};
