import { Box } from '@mui/material';
import { actionIconBtnSx, imgStyle } from './ActionIconButton.style';

export const ActionIconButton = ({ iconSrc, label }) => {
  return (
    <Box sx={actionIconBtnSx}>
      <img src={iconSrc} alt={label} width="20" height="20" style={imgStyle} />
      {label}
    </Box>
  );
};
