import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContainer } from '../AuthContainer/AuthContainer.component';
import { HeaderWithLogo } from '../HeaderWithLogo/HeaderWithLogo.component';
import { useResponsive } from '../../hooks/useResponsive';
import { containerSx } from '../../pages/SignUp/components/SignUpFormWrapper/SignUpForm.style';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton.component';

export const CheckEmail = ({ action }) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const email = localStorage.getItem('email');

  const backHandler = () => {
    localStorage.removeItem('email');
    navigate('/login');
  };

  return (
    <AuthContainer>
      <Box
        sx={{
          padding: '0px 128px',
          display: `${isMobile ? 'none' : 'grid'}`,
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Container sx={containerSx}>
          <HeaderWithLogo title="Check Your Email" />
          <Typography textAlign="center" sx={{ marginBottom: '32px' }}>
            Please check the email address
            <Typography
              component="span"
              sx={{ fontWeight: '500', padding: '0 3px' }}
            >
              {email}
            </Typography>
            {` for instructions to ${action}.`}
          </Typography>
          <PrimaryButton onClick={backHandler} label="back">
            Back
          </PrimaryButton>
        </Container>
      </Box>
    </AuthContainer>
  );
};
