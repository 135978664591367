import { Box, Container } from '@mui/material';
import { HeaderWithLogo } from '../HeaderWithLogo/HeaderWithLogo.component';
import { SetNewPasswordForm } from './components/SetNewPasswordForm/SetNewPasswordForm.component';

export const SetNewPassword = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F4F6FB',
      }}
    >
      <Container sx={{ padding: '60px' }}>
        <HeaderWithLogo title="Set new password" />
        <SetNewPasswordForm />
      </Container>
    </Box>
  );
};
