import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PrivacyPolicyAndTermsOfUse } from '../../../../../components/PrivacyPolicy&TermsOfUse/PrivacyPolicy&TermsOfUse.component';
import { HeaderWithLogo } from '../../../../../components/HeaderWithLogo/HeaderWithLogo.component';
import { LoginForm } from '../LoginForm/LoginForm.component';

export const LoginModal = ({
  open,
  handleClose,
  handleOpenSignUp,
  handleOpenRecovery,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <HeaderWithLogo title="Login into SuperPrime.Bot">
          <Box
            sx={{ padding: '8px 0 16px', gap: '0.75rem' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography sx={{ minWidth: '147px' }} variant="body2">
              Don't have an account?
            </Typography>
            <Link
              component="button"
              onClick={handleOpenSignUp}
              underline="none"
              variant="body2"
              pl={1}
              sx={{ minWidth: '105px', fontWeight: 700 }}
            >
              Create account
            </Link>
          </Box>
        </HeaderWithLogo>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoginForm
          closeModal={handleClose}
          handleOpenRecovery={handleOpenRecovery}
        />
      </DialogContent>

      <Box sx={{ padding: '10px 0 24px', maxWidth: '364px' }}>
        <PrivacyPolicyAndTermsOfUse />
      </Box>
    </Dialog>
  );
};
