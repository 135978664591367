import * as Yup from 'yup';

export const validationSchema = Yup.object({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  phone_number: Yup.string(),
});

export const initialValues = {
  first_name: '',
  last_name: '',
  phone_number: '',
};
