import { AuthContainer } from '../../components/AuthContainer/AuthContainer.component';
import { SignUpFormWrapper } from './components/SignUpFormWrapper/SignUpFormWrapper.component';

export const SignUp = () => {
  return (
    <AuthContainer>
      <SignUpFormWrapper />
    </AuthContainer>
  );
};
