export const listContainer = {
  gridTemplateRows: 'max-content 34vh',
  '@media (max-height: 895px)': {
    gridTemplateRows: 'max-content 32vh',
  },
  '@media (max-height: 741px)': {
    gridTemplateRows: 'max-content 28vh',
  },
  '@media (max-height: 639px)': {
    gridTemplateRows: 'max-content 26vh',
  },
};

export const listSx = {
  overflow: 'auto',
  '@media (max-height: 731px)': {
    paddingTop: '0px',
  },
};

export const titleSx = {
  '@media (max-height: 731px)': {
    paddingTop: '10px',
  },
};
