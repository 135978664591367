import './App.css';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { LayoutResolver } from './components/LayoutResolver/LayoutResolver';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ProfileProvider } from './context/profileContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FavoritesProvider } from './context/favoritesContext';
import { ConversationsProvider } from './context/conversationsContext';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ProfileProvider>
          <FavoritesProvider>
            <ConversationsProvider>
              <ThemeProvider theme={theme}>
                <BrowserRouter>
                  <LayoutResolver />
                </BrowserRouter>
              </ThemeProvider>
            </ConversationsProvider>
          </FavoritesProvider>
        </ProfileProvider>
        <ToastContainer />
      </QueryClientProvider>
    </div>
  );
}

export default App;
