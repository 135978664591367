import { Box, Typography } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive';

export const MainLabel = ({ height }) => {
  const { isMobile } = useResponsive();
  return (
    <Box sx={{ color: '#6B6B6B', ...(height && { height }) }}>
      <Typography
        sx={{ fontSize: isMobile ? '1.25rem' : '1.5rem', fontWeight: 700 }}
      >
        SuperPrime.Bot
      </Typography>
      <Typography
        sx={{
          fontSize: isMobile ? '0.625rem' : '0.75rem',
          textAlign: 'center',
        }}
      >
        AI-driven property search
      </Typography>
    </Box>
  );
};
