import { Box, Button, Typography } from '@mui/material';
import { PropertyValueContainer } from '../PropertyValueContainer/PropertyValueContainer.component';
import { useResponsive } from '../../../../hooks/useResponsive';

export const PropertyContactInfo = ({ contactData }) => {
  const email = contactData.email;
  const { isMobile } = useResponsive();
  const handleContactHost = () => {
    const subject = encodeURIComponent('property');
    const emailBody = encodeURIComponent('');
    window.location.href = `mailto:${
      email && email
    }?subject=${subject}&body=${emailBody}`;
  };
  return (
    <PropertyValueContainer title="Contact Info">
      <Box
        display="flex"
        gap={`${isMobile ? '12px' : '32px'}`}
        alignItems="center"
        flexWrap="wrap"
      >
        <Box display="flex" gap="12px">
          <Typography>{contactData.fullName}</Typography>
          <Typography>{contactData.phone}</Typography>
        </Box>

        <Typography>{contactData.email}</Typography>
        {email && (
          <Box width={`${isMobile ? '100%' : 'auto'}`}>
            <Button
              sx={{ width: '100%' }}
              onClick={handleContactHost}
              variant="contained"
            >
              Contact host
            </Button>
          </Box>
        )}
      </Box>
    </PropertyValueContainer>
  );
};
