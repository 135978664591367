import { EmailShareButton } from 'react-share';
import { ActionIconButton } from '../ActionIconButton/ActionIconButton.component';
import { Box } from '@mui/material';
import shareIcon from '../../assets/icons/share.png';

export const ShareByEmailBtn = ({ url, subject, body }) => {
  return (
    <Box>
      <EmailShareButton url={url} subject={subject} body={body}>
        <ActionIconButton iconSrc={shareIcon} label="share" />
      </EmailShareButton>
    </Box>
  );
};
