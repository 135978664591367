import { toast } from 'react-toastify';
import { useResponsive } from './useResponsive';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export const useToast = () => {
  const { isMobile } = useResponsive();

  const showSuccessToast = (message) => {
    toast.success(message, {
      position: `${isMobile ? 'bottom-center' : 'bottom-right'}`,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      icon: <CheckCircleRoundedIcon sx={{ color: '#16C2CC' }} />,
    });
  };

  const showErrorToast = (message) => {
    toast.error(message ? message : 'Something went wrong', {
      position: `${isMobile ? 'bottom-center' : 'top-center'}`,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return { showSuccessToast, showErrorToast };
};
