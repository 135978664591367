export const actionIconBtnSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textTransform: 'capitalize',
  backgroundColor: '#F4F6FB',
  borderRadius: '40px',
  color: '#666666',
  fontWeight: 500,
  fontSize: 16,
  padding: '10px 12px',
  '&:hover': {
    backgroundColor: '#E3E5ED',
  },
};

export const imgStyle = {
  paddingRight: '8px',
};
