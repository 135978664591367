import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    '& input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      WebkitTextFillColor: '#000',
    },
    '& input:-webkit-autofill:hover': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      WebkitTextFillColor: '#000',
    },
    '& input:-webkit-autofill:focus': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      WebkitTextFillColor: '#000',
    },
    '& input:-webkit-autofill:active': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      WebkitTextFillColor: '#000',
    },
  },
  '&.Mui-focused fieldset': {
    borderColor: '#3490DC !important',
  },
  '&.MuiFormControl-root': {
    margin: '8px 0',
  },
}));
