import { Box, Link, Typography } from '@mui/material';
import { ShareByEmailBtn } from '../../../../../ShareByEmailBtn/ShareByEmailBtn.component';
import { idSx, propertyContainerSx } from './Property.style';
import { propertyService } from '../../../../../../api/PropertyService';
import { useToast } from '../../../../../../hooks/UseToast';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { FavoriteIconBtn } from '../../../../../FavoriteIconBtn/FavoriteIconBtn.component';
import { getTitle } from '../../../../../../utils/utils';
import ReactMarkdown from 'react-markdown';

export const Property = ({ property }) => {
  const { showErrorToast } = useToast();
  const [isFavorite, setIsFavorite] = useState(false);
  const [favId, setFavId] = useState('');
  const { data: favoritesData, refetch: refetchFavorites } = useQuery(
    'favorites',
    propertyService.fetchFavorites,
    {
      onError: (error) => {
        showErrorToast();
      },
    }
  );

  const isFullAccessProperty = property.access_level === 'full_access';
  const hasPrice = !!property.price;
  function formatPrice(price) {
    return `£${new Intl.NumberFormat('en-GB').format(price)}`;
  }

  const displayPrice = hasPrice
    ? formatPrice(property.price)
    : !isFullAccessProperty
      ? 'Price On Application'
      : '';

  const updatedDescription =
    property.description &&
    property.description.replace(
      /\s*\b[^.]*\b(?:is|at)\s*£[\d,]+(?:\.\d+)?[^.]*\.\s*/g,
      ''
    );

  useEffect(() => {
    favoritesData?.results.forEach((fav) => {
      if (fav.property.id === property.id) {
        setIsFavorite(true);
        setFavId(fav.id);
      }
    });
  }, [property, favoritesData]);

  const toggleFavoriteHandler = () => {
    if (isFavorite) {
      favId &&
        propertyService
          .removeFromFavorites(favId)
          .then(() => {
            refetchFavorites();
            setIsFavorite(false);
          })
          .catch((error) => showErrorToast());
    } else {
      const propertyData = {
        property: property.id,
        description: property.description,
      };
      propertyService
        .addToFavorite(propertyData)
        .then((response) => {
          refetchFavorites();
          setIsFavorite(true);
          setFavId(response.id);
        })
        .catch((error) => {
          showErrorToast(error.response.data.property[0]);
        });
    }
  };

  return (
    <Box sx={propertyContainerSx}>
      <Box>
        {isFullAccessProperty ? (
          property.property_url ? (
            <Link
              variant="body1"
              target="_blank"
              rel="noopener noreferrer"
              href={property.property_url}
            >
              {getTitle(property)}
            </Link>
          ) : (
            <Typography sx={{ fontWeight: '500' }}>
              {getTitle(property)}
              <Typography sx={idSx}> {`ID: ${property.id}`}</Typography>
            </Typography>
          )
        ) : (
          <Typography sx={{ fontWeight: '500' }}>
            Exclusive Off Market Property
            <Typography sx={idSx}>{`ID: ${property.id}`}</Typography>
            {property.contact_full_name && (
              <Typography
                sx={idSx}
              >{`Contact: ${property.contact_full_name} `}</Typography>
            )}
            {property.contact_phone && (
              <Typography
                sx={idSx}
              >{`Phone: ${property.contact_phone}`}</Typography>
            )}
            {property.contact_email && (
              <Typography
                sx={idSx}
              >{`Email: ${property.contact_email}`}</Typography>
            )}
            {property.second_address && (
              <Typography
                sx={idSx}
              >{`Address: ${property.second_address}`}</Typography>
            )}
          </Typography>
        )}
        <Typography
          sx={{
            marginBottom: !hasPrice && isFullAccessProperty ? '24px' : '',
          }}
        >
          {property.description ? (
            <ReactMarkdown>{updatedDescription}</ReactMarkdown>
          ) : (
            'no description'
          )}
        </Typography>
        {displayPrice && (
          <Typography sx={{ marginBottom: '24px', paddingTop: '8px' }}>
            {displayPrice}
          </Typography>
        )}
        <ShareByEmailBtn
          url={property.property_url}
          subject="subject"
          body={property.description}
        />
      </Box>
      <Box>
        <FavoriteIconBtn
          clickHandler={toggleFavoriteHandler}
          isFavorite={isFavorite}
        />
      </Box>
    </Box>
  );
};
