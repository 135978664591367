import { AuthContainer } from '../../components/AuthContainer/AuthContainer.component';
import { LoginFormWrapper } from './LoginFormWrapper/LoginFormWrapper.component';

export const Login = () => {
  return (
    <AuthContainer>
      <LoginFormWrapper />
    </AuthContainer>
  );
};
