import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import { ChatInputSx, sendBtnSx } from './ChatInput.style';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import send from './../../../../assets/icons/send.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useToast } from '../../../../hooks/UseToast';
import { chatService } from '../../../../api/ChatService';
import StopIcon from '@mui/icons-material/Stop';
import { useAudioRecorder } from '../../../../hooks/UseAudioRecorder';
import { RecordingAnimation } from '../../../RecordingAnimation/RecordingAnimation.component';

export const ChatInput = ({ isResponsing }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [message, setMessage] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  const [size, setSize] = useState(40);
  const [growing, setGrowing] = useState(true);
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const { refetch: refetchConversations } = useQuery(
    'conversations',
    chatService.fetchConversations
  );
  const { refetch } = useQuery(['messages', conversationId], () =>
    chatService.fetchMessages(conversationId)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSize((prevSize) => {
        if (prevSize >= 60) {
          setGrowing(false);
          return prevSize - 2;
        } else if (prevSize <= 40) {
          setGrowing(true);
          return prevSize + 2;
        }
        return growing ? prevSize + 2 : prevSize - 2;
      });
    }, 200);

    return () => clearInterval(interval);
  }, [growing]);

  const changeHandler = (e) => {
    setMessage(e.target.value);
  };

  const isSendBtnDisadled = isResponsing || message === '';

  const handleKeyPress = (e) => {
    if (isResponsing) return;
    if (e.key === 'Enter' && message.trim()) {
      e.preventDefault();
      sendMessageHandler();
    }
  };
  const { startRecording, stopRecording } = useAudioRecorder(
    async (blob) => {
      setIsConverting(true);
      const formData = new FormData();
      const fileName = 'record.wav';
      formData.append('file', blob, fileName);

      try {
        const response = await chatService.transcribeAudio(formData);
        setMessage(response.transcription);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsConverting(false);
      }
    },
    (error) => {
      console.error(error);
    },
    setIsRecording
  );

  const sendChatMessage = (content) => {
    if (conversationId) {
      chatService
        .sendMessage(conversationId, { content })
        .then(() => refetch())
        .catch((error) => showErrorToast());
    } else {
      chatService
        .createNewConversation()
        .then((data) => {
          navigate(`/conversation/${data.id}`, { replace: true });
          return chatService.sendMessage(data.id, { content });
        })
        .then(() => {
          setMessage('');
          refetchConversations().catch((error) => showErrorToast());
        })
        .catch((error) => showErrorToast());
    }
  };

  const sendMessageHandler = () => {
    sendChatMessage(message);
    setMessage('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={ChatInputSx}>
        {!isRecording && (
          <TextField
            fullWidth
            multiline
            maxRows={4}
            onKeyDown={handleKeyPress}
            placeholder={isRecording ? '' : 'Describe your ideal property'}
            value={message}
            variant="standard"
            onChange={changeHandler}
            InputProps={{
              disableUnderline: true,
              startAdornment: isConverting && (
                <InputAdornment position="start">
                  <CircularProgress style={{ height: '25px', width: '25px' }} />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={{
              backgroundColor: 'white',
            }}
          />
        )}
        {isRecording && <RecordingAnimation />}
        {isRecording ? (
          <Box
            sx={{
              width: '40px',
              height: '40px',
              display: 'inline-block',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={stopRecording}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '25%',
                transform: 'translate(-50%, -50%)',
                width: `${size}px`,
                height: `${size}px`,
                transition: 'width 0.2s ease, height 0.2s ease',
              }}
            >
              <StopIcon
                sx={{ color: '#FF0303', width: '100%', height: '100%' }}
              />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            disabled={isResponsing || isConverting}
            onClick={startRecording}
          >
            <MicIcon />
          </IconButton>
        )}
      </Box>
      <Box
        component="button"
        disabled={isSendBtnDisadled}
        onClick={sendMessageHandler}
        sx={{
          ...sendBtnSx,
          cursor: `${isSendBtnDisadled ? 'not-allowed' : 'pointer'}`,
        }}
      >
        <img width="19" height="16" src={send} alt="send" />
      </Box>
    </Box>
  );
};
