import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { initialValues, validationSchema } from './SetNewPasswordForm.utils';
import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useShowPassword } from '../../../../hooks/FormHooks/useShowPassword.hook';
import { useToast } from '../../../../hooks/UseToast';
import { WhiteBackgroundTextField } from '../../../WhiteBackgroundTextField/WhiteBackgroundTextField';
import { authService } from '../../../../api/AuthService';

export const SetNewPasswordForm = ({ handleClose }) => {
  const navigate = useNavigate();
  const [
    showPassword,
    handlePasswordVisibility,
    showConfirmPassword,
    handleConfirmPasswordVisibility,
  ] = useShowPassword();
  const { showErrorToast, showSuccessToast } = useToast();
  const { uid, token } = useParams();

  const goBackHandler = () => navigate('/login');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      authService
        .resetPasswordConfirm({ new_password: values.new_password, uid, token })
        .then(() => {
          handleClose && handleClose();
          showSuccessToast('New password has been successfully set');
          resetForm();
        })
        .catch((error) => {
          showErrorToast();
        });
    },
  });
  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      style={{ paddingTop: '24px' }}
    >
      <WhiteBackgroundTextField
        required
        fullWidth
        name="new_password"
        label="New password"
        type={showPassword ? 'text' : 'password'}
        id="new-password"
        value={formik.values.new_password}
        autoComplete="new_password"
        onChange={formik.handleChange}
        error={
          formik.touched.new_password && Boolean(formik.errors.new_password)
        }
        helperText={formik.touched.new_password && formik.errors.new_password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <WhiteBackgroundTextField
        required
        fullWidth
        name="confirm_password"
        label="Confirm password"
        type={showConfirmPassword ? 'text' : 'password'}
        id="confirm-password"
        value={formik.values.confirm_password}
        autoComplete="confirm_password"
        onChange={formik.handleChange}
        error={
          formik.touched.confirm_password &&
          Boolean(formik.errors.confirm_password)
        }
        helperText={
          formik.touched.confirm_password && formik.errors.confirm_password
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleConfirmPasswordVisibility} edge="end">
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ paddingTop: '24px' }}
      >
        <Button
          onClick={goBackHandler}
          sx={{ color: '#6B6B6B', width: '50%' }}
          variant="text"
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ width: '50%' }}
          disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
        >
          Save
        </Button>
      </Box>
    </form>
  );
};
