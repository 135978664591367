import { Box, Button } from '@mui/material';
import { WhiteBackgroundTextField } from '../../../WhiteBackgroundTextField/WhiteBackgroundTextField';
import { useFormik } from 'formik';

import {
  initialValues,
  resolvePage,
  validationSchema,
} from './PasswordRecoveryForm.utils';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../../hooks/UseToast';
import { authService } from '../../../../api/AuthService';
import { PrimaryButton } from '../../../PrimaryButton/PrimaryButton.component';

export const PasswordRecoveryForm = ({
  handleOpenCheckEmail,
  isSettingsPage,
}) => {
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  const handleGoBack = () => navigate(-1);
  const handlePasswordRecovery = (email) => {
    authService
      .resetPasswordRequest(email)
      .then(() => resolvePage(handleOpenCheckEmail, navigate, isSettingsPage))
      .catch((error) => showErrorToast());
    localStorage.setItem('email', email);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      handlePasswordRecovery(values.email);
      resetForm();
      handleOpenCheckEmail && handleOpenCheckEmail();
    },
  });

  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      style={{ paddingTop: '8px' }}
    >
      <WhiteBackgroundTextField
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        autoFocus
      />
      <Box display="flex" sx={{ gap: '0.875rem' }}>
        <Button
          onClick={handleGoBack}
          sx={{ color: '#6B6B6B', width: '184px' }}
          variant="text"
        >
          Back
        </Button>
        <PrimaryButton
          sx={{
            width: '50%',
            height: '3.25rem',
            padding: '0.9375rem 1.875rem',
          }}
          label="Continue"
          disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
        />
      </Box>
    </form>
  );
};
