import { Box, Grid, Typography } from '@mui/material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { UserIcon } from '../UserIcon/UserIcon.component';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '../../hooks/useResponsive';
import { useProfile } from '../../context/profileContext';
import { MainLabel } from '../MainLabel/MainLabel.component';

export const Navbar = ({ handleOpenDrawer }) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const firstName = profile && profile.first_name;

  const openHelpHandler = () =>
    window.open(
      'https://romantic-beech-3d6.notion.site/HomeHapp-AI-3f069d1d7d89441fb2bbc91c3a5f7e40',
      '_blank'
    );
  return (
    <Grid>
      <Box
        sx={{
          padding: `${isMobile ? '25px 24px' : '15px 64px 14px 45px'}`,
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `${isMobile ? '0.5px solid #6B6B6B' : 'none'}`,
        }}
      >
        {isMobile && (
          <Box onClick={handleOpenDrawer}>
            <MenuIcon fontSize="medium" />
          </Box>
        )}
        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <MainLabel height="40px" />
        </Box>
        <Box display="flex" alignItems="center" color="#6B6B6B" p="5">
          {!isMobile && (
            <Box onClick={openHelpHandler} sx={{ cursor: 'pointer' }} pt="5px">
              <HelpOutlineRoundedIcon />
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            onClick={() => navigate('/profile-settings')}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Box pl="26px">
              <UserIcon height="32px" width="32px" username={firstName} />
            </Box>
            {!isMobile && (
              <Typography
                sx={{
                  fontWeight: 500,
                  paddingLeft: '10px',
                }}
              >
                {firstName}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
