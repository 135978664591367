import { Box } from '@mui/material';

export const IconCircleContainer = ({ children, clickHandler }) => {
  return (
    <Box
      onClick={clickHandler}
      sx={{
        height: '24px',
        borderRadius: '50%',
        padding: '8px',
        '&:hover': {
          backgroundColor: '#E3E3E3',
          cursor: 'pointer',
          '& .MuiSvgIcon-root': {
            color: '#3490DC',
          },
        },
      }}
    >
      {children}
    </Box>
  );
};
