import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: '48px',
      fontWeight: 700,
      '@media (max-width:600px)': {
        fontSize: '36px',
      },
    },
    subtitle1: {
      fontSize: '24px',
      fontWeight: 400,
      color: '#666666',
      '@media (max-width:600px)': {
        fontSize: '18px',
      },
    },
  },
  palette: {
    primary: {
      main: '#3490DC',
    },
    secondary: {
      main: '#FFC107',
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
});
