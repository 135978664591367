import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../../hooks/UseToast';
import { useQuery } from 'react-query';
import { chatService } from '../../../../../../api/ChatService';
import { useResponsive } from '../../../../../../hooks/useResponsive';

export const Suggestion = ({ text }) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { conversationId } = useParams();
  const { refetch: refetchConversations } = useQuery(
    'conversations',
    chatService.fetchConversations
  );
  const { showErrorToast } = useToast();

  const { refetch } = useQuery(
    ['messages', conversationId],
    () => chatService.fetchMessages(conversationId),
    {
      onError: (error) => {
        showErrorToast();
      },
    }
  );

  const handleSuggestionClick = () => {
    if (conversationId) {
      chatService
        .sendMessage(conversationId, { content: text })
        .then(() => refetch())
        .catch((error) => showErrorToast());
    } else {
      chatService
        .createNewConversation()
        .then((data) => {
          navigate(`/conversation/${data.id}`, { replace: true });
          chatService
            .sendMessage(data.id, { content: text })
            .catch((error) => showErrorToast());
          refetchConversations().catch((error) => showErrorToast());
        })
        .catch((error) => showErrorToast());
    }
  };
  return (
    <Box
      onClick={handleSuggestionClick}
      sx={{
        borderRadius: `1.0625rem`,
        backgroundColor: '#F7F7F7',
        padding: '12px 14px',
        paddingRight: isMobile ? '0px' : '210px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: `${isMobile ? '14px' : 'inherit'}`,
        }}
        color="primary"
      >
        {text}
      </Typography>
    </Box>
  );
};
