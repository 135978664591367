import { axiosInstance } from './api';

class ProfileService {
  async fetchProfile() {
    const { data } = await axiosInstance.get(`profile/`);
    return data;
  }

  async updateProfile(profileData) {
    const { data } = await axiosInstance.patch('profile/', profileData);
    return data;
  }
}

export const profileService = new ProfileService();
