import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SignUpForm } from '../SignUpForm/SignUpForm.component';
import { HeaderWithLogo } from '../../../../../../components/HeaderWithLogo/HeaderWithLogo.component';

export const SignupModal = ({
  open,
  handleClose,
  handleOpenLogin,
  handleOpenCheckEmailAfterSignUp,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <HeaderWithLogo title="Create Your SuperPrime.Bot account">
          <Box
            sx={{ padding: '8px 0 0px', gap: '0.75rem' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2">Already have an account?</Typography>
            <Link
              onClick={handleOpenLogin}
              sx={{ fontWeight: 700 }}
              variant="body2"
              component="button"
              underline="none"
              pl={1}
            >
              Login
            </Link>
          </Box>
        </HeaderWithLogo>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SignUpForm
          closeModal={handleClose}
          handleOpenCheckEmailAfterSignUp={handleOpenCheckEmailAfterSignUp}
        />
      </DialogContent>
    </Dialog>
  );
};
