import { Box, Typography } from '@mui/material';
import logo from '../../assets/icons/logo.png';
import { useResponsive } from '../../hooks/useResponsive';

export const HeaderWithLogo = ({ title, children }) => {
  const { isMobile } = useResponsive();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={logo} alt="logo" height="68px" width="68px" />
      <Typography
        mt={2}
        sx={{
          fontWeight: 500,
          fontSize: `${isMobile ? '1.125rem' : '1.5rem'}`,
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
