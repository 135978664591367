export const containerSx = {
  backgroundColor: '#F7F7F7',
  padding: '10px 20px',
  margin: '20px 0 0',
  borderRadius: '8px',
};

export const sendBtnSx = {
  padding: '8px 16px',
  color: '#6B6B6B',
  fontWeight: '500',
};
