import React from 'react';
import { Typography, Box, Link, Container } from '@mui/material';
import { SignUpForm } from './components/SignUpForm/SignUpForm.component';
import { useResponsive } from '../../../../hooks/useResponsive';
import { HeaderWithLogo } from '../../../../components/HeaderWithLogo/HeaderWithLogo.component';
import { containerSx } from './SignUpForm.style';

export const SignUpFormWrapper = () => {
  const { isMobile } = useResponsive();

  return (
    <>
      <Box
        sx={{
          padding: '0px 70px',
          display: `${isMobile ? 'none' : 'grid'}`,
          height: '100vh',
        }}
      >
        <Container sx={containerSx}>
          <HeaderWithLogo title=" Create your account">
            <Box
              sx={{ padding: '8px 0 24px' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography sx={{ fontSize: '1rem' }}>
                Already have an account?
              </Typography>
              <Link
                sx={{ fontWeight: 700 }}
                variant="body2"
                href="/login"
                underline="none"
                pl={1}
              >
                Login
              </Link>
            </Box>
          </HeaderWithLogo>

          <SignUpForm />
        </Container>
      </Box>
    </>
  );
};
