import { axiosInstance } from './api';
import { storeToken } from './helpers';

class AuthService {
  async signUp(userData) {
    const { data } = await axiosInstance.post(`auth/signup/`, userData);
    return data;
  }

  async login(userData) {
    const { data } = await axiosInstance.post(`auth/login/`, userData);
    storeToken(data.access_token);
    return data;
  }

  async logout() {
    const { data } = await axiosInstance.post('auth/logout/');
    return data;
  }

  async resetPasswordRequest(email) {
    const { data } = await axiosInstance.post('auth/password-reset-request/', {
      email,
    });
    return data;
  }

  async resetPasswordConfirm(passwordData) {
    const { data } = await axiosInstance.post(
      'auth/password-reset-confirm/',
      passwordData
    );
    return data;
  }

  async changePassword(passwordsData) {
    const { data } = await axiosInstance.put(
      '/password-update/',
      passwordsData
    );
    return data;
  }

  async confirmEmail(id, token) {
    const { data } = await axiosInstance.get(
      `auth/confirm-email-complete/${id}/${token}/`
    );
    return data;
  }
}

export const authService = new AuthService();
