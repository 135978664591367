import { Box, Typography } from '@mui/material';
import { useResponsive } from '../../../../hooks/useResponsive';

export const PropertyValueContainer = ({ title, children }) => {
  const isLink = title === 'Property Link';
  const { isMobile } = useResponsive();
  return (
    <Box
      borderTop="0.5px solid #6B6B6B"
      padding="24px 0"
      gap="12px"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: `${isLink ? 'nowrap' : 'wrap'}`,
        maxWidth: `${isMobile ? '320px' : '100%'}`,
      }}
    >
      <Typography fontSize="20px" fontWeight="500" paddingBottom="12px">
        {title}
      </Typography>
      {children}
    </Box>
  );
};
