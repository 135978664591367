import { Button } from '@mui/material';

export const PrimaryButton = ({ label, sx = {}, ...props }) => {
  return (
    <Button
      sx={{
        padding: '15px 30px',
        color: '#003366',
        fontWeight: 600,
        width: sx.width || '100%',
        '&:hover': {
          backgroundColor: '#FF9407',
        },
        ...sx,
      }}
      variant="contained"
      color="secondary"
      type="submit"
      {...props}
    >
      {label}
    </Button>
  );
};
