import { useResponsive } from '../../../../hooks/useResponsive';
import { Box, Container } from '@mui/material';
import { HeaderWithLogo } from '../../../HeaderWithLogo/HeaderWithLogo.component';
import { PasswordRecoveryForm } from '../../../PasswordRecovery/components/PasswordRecoveryForm/PasswordRecoveryForm.component';

export const SettingsPasswordRecovery = () => {
  const { isMobile } = useResponsive();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: `${isMobile ? 'none' : 'grid'}`,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container sx={{ padding: '60px' }}>
        <HeaderWithLogo title="Password recovery" />
        <PasswordRecoveryForm isSettingsPage={true} />
      </Container>
    </Box>
  );
};
