import { Box, Link, Typography } from '@mui/material';
import { btnSx } from './PrivacyPolicy&TermsOfUse.style';
import { useState } from 'react';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy.component';
import { TermsAndConditions } from './components/TermsAndConditions/TermsAndConditions.component';

export const PrivacyPolicyAndTermsOfUse = () => {
  const [privatPolicyOpen, setPrivatPolicyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const openTerms = () => {
    setTermsOpen(true);
  };
  const closeTerms = () => setTermsOpen(false);

  const openPrivatePolicy = () => {
    setPrivatPolicyOpen(true);
  };
  const closePrivacyPolicy = () => setPrivatPolicyOpen(false);
  return (
    <Box
      sx={{
        color: '#3C3C4399',
        fontSize: '14px',
        textAlign: 'center',
        paddingBottom: '40px',
      }}
    >
      <Typography component="span">By continuing you agree to the </Typography>
      <Typography component="span">
        <Link
          onClick={openTerms}
          component="button"
          underline="none"
          style={{ marginRight: '4px' }}
          sx={btnSx}
          variant="text"
        >
          Terms and Conditions
        </Link>
        and
        <Link
          onClick={openPrivatePolicy}
          component="button"
          underline="none"
          style={{ marginLeft: '4px' }}
          sx={btnSx}
          variant="text"
        >
          Privacy policy
        </Link>
      </Typography>
      <PrivacyPolicy open={privatPolicyOpen} handleClose={closePrivacyPolicy} />
      <TermsAndConditions open={termsOpen} handleClose={closeTerms} />
    </Box>
  );
};
