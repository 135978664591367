import { centeredFlexSx } from '../../../../../../layouts/MainContentLayout/MainContent.style';

export const propertyContainerSx = {
  borderTop: 'solid #E1E1E1',
  borderWidth: 'thin',
  padding: '16px 0',
  display: 'grid',
  gridTemplateColumns: '1fr 44px',
  marginTop: '16px',
};

export const iconWrapperSx = {
  ...centeredFlexSx,
  borderRadius: '50%',
  padding: '8px',
  '&:hover': {
    backgroundColor: '#E3E3E3',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: '#3490DC',
    },
  },
};

export const idSx = { color: '#6B6B6B', fontStyle: 'italic' };
