import * as Yup from 'yup';

export const validationSchema = Yup.object({
  old_password: Yup.string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Required'),
  new_password: Yup.string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Required'),
});

export const initialValues = {
  old_password: '',
  new_password: '',
};
