import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { LoginModal } from '../../../../pages/Login/LoginFormWrapper/components/LoginModal/LoginModal.component';
import { PasswordRecoveryModal } from '../../../PasswordRecovery/components/PasswordRecoveryModal/PasswordRecoveryModal.component';
import { CheckEmailModal } from '../../../CheckEmail/CheckEmailModal/CheckEmailModal';
import { SignupModal } from '../../../../pages/SignUp/components/SignUpFormWrapper/components/SignupModal/SignupModal.component';
import { CheckEmailAfterSignUpModal } from '../../../CheckEmail/CheckEmailAfterSignUpModal /CheckEmailAfterSignUpModal ';
import {
  containerSx,
  createAccBtnSx,
  loginBtnSx,
} from './MobileLoginSignUpButtons.style';

export const MobileLoginSignupButtons = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isRecoveryOpen, setIsRecoveryOpen] = useState(false);
  const [isCheckEmailOpen, setIsCheckEmailOpen] = useState(false);
  const [isCheckEmailAfterSignUpOpen, setIsCheckEmailAfterSignUpOpen] =
    useState(false);

  const openLoginModal = () => {
    setIsSignupOpen(false);
    setIsLoginOpen(true);
  };
  const openSignupModal = () => {
    setIsLoginOpen(false);
    setIsSignupOpen(true);
  };
  const openRecoveryModal = () => {
    setIsLoginOpen(false);
    setIsRecoveryOpen(true);
  };
  const openCheckEmailModal = () => {
    setIsRecoveryOpen(false);
    setIsCheckEmailOpen(true);
  };
  const openCheckEmailAfterSignUpModal = () => {
    setIsSignupOpen(false);
    setIsCheckEmailAfterSignUpOpen(true);
  };
  const closeCheckEmailModal = () => {
    setIsCheckEmailOpen(false);
    setIsLoginOpen(true);
  };
  const closeCheckEmailAfterSignUpModal = () => {
    setIsCheckEmailAfterSignUpOpen(false);
    setIsLoginOpen(true);
  };
  const closeLoginModal = () => setIsLoginOpen(false);
  const closeSignupModal = () => setIsSignupOpen(false);
  const closeRecoveryModal = () => {
    setIsLoginOpen(true);
    setIsRecoveryOpen(false);
  };

  return (
    <Box sx={containerSx}>
      <Button
        sx={createAccBtnSx}
        onClick={openSignupModal}
        variant="contained"
        color="secondary"
      >
        Create Account
      </Button>
      <Button sx={loginBtnSx} onClick={openLoginModal} variant="contained">
        Login
      </Button>
      <LoginModal
        open={isLoginOpen}
        handleClose={closeLoginModal}
        handleOpenSignUp={openSignupModal}
        handleOpenRecovery={openRecoveryModal}
      />
      <SignupModal
        open={isSignupOpen}
        handleClose={closeSignupModal}
        handleOpenLogin={openLoginModal}
        handleOpenCheckEmailAfterSignUp={openCheckEmailAfterSignUpModal}
      />
      <PasswordRecoveryModal
        open={isRecoveryOpen}
        handleClose={closeRecoveryModal}
        handleOpenCheckEmail={openCheckEmailModal}
      />
      <CheckEmailModal
        open={isCheckEmailOpen}
        handleClose={closeCheckEmailModal}
      />
      <CheckEmailAfterSignUpModal
        open={isCheckEmailAfterSignUpOpen}
        handleClose={closeCheckEmailAfterSignUpModal}
      />
    </Box>
  );
};
