import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderWithLogo } from '../../../HeaderWithLogo/HeaderWithLogo.component';
import { PasswordRecoveryForm } from '../PasswordRecoveryForm/PasswordRecoveryForm.component';

export const PasswordRecoveryModal = ({
  open,
  handleClose,
  handleOpenCheckEmail,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <HeaderWithLogo title="Password recovery" />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PasswordRecoveryForm handleOpenCheckEmail={handleOpenCheckEmail} />
      </DialogContent>
    </Dialog>
  );
};
