import * as Yup from 'yup';

export const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
});

export const initialValues = {
  email: '',
};

export const resolvePage = (handleOpenCheckEmail, navigate, isSettings) => {
  if (handleOpenCheckEmail) {
    handleOpenCheckEmail();
    return;
  } else {
    isSettings ? navigate('/settings-check-email') : navigate('/check-email');
  }
};
