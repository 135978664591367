import { Box, List, ListItem, Typography } from '@mui/material';
import { SidebarListItem } from '../SidebarListItem/SidebarListItem.component';
import { useResponsive } from '../../../../hooks/useResponsive';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTitle } from '../../../../utils/utils';
import { listContainer, listSx, titleSx } from './SidebarList.style';

export const SidebarList = ({ title, items, selectHandler, isFavorite }) => {
  const { isMobile } = useResponsive();
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { conversationId } = useParams();

  const handleSelect = (id) => {
    setSelectedItemId(id);
    selectHandler(id);
  };

  useEffect(() => {
    conversationId && setSelectedItemId(conversationId);
  }, [conversationId]);

  return (
    <Box display="grid" sx={listContainer}>
      {items?.length ? (
        <Typography
          sx={titleSx}
          paddingTop={`${isMobile ? '20px' : '40px'}`}
          color="#6B6B6B"
        >
          {title}
        </Typography>
      ) : null}
      <List sx={listSx}>
        {items &&
          items.map((item) => (
            <ListItem
              sx={{
                padding: '0',
                backgroundColor:
                  item.id === selectedItemId ? '#F7F7F7' : 'transparent',
              }}
              key={item.id}
              onClick={() => handleSelect(item.id)}
            >
              {isFavorite ? (
                <SidebarListItem>{getTitle(item.property)}</SidebarListItem>
              ) : (
                <SidebarListItem>
                  {item.first_message_from_conversation
                    ? item.first_message_from_conversation
                    : 'new chat'}
                </SidebarListItem>
              )}
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
