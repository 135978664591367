import React from 'react';
import animationData from './../../assets/animations/recording-animation.json';
import Lottie from 'lottie-react';
import { Box } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive';

export const RecordingAnimation = () => {
  const { isMobile } = useResponsive();
  return (
    <Box sx={{ display: 'flex', padding: isMobile ? '0 20px' : '0 60px' }}>
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{ height: 40 }}
      />
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{ height: 40 }}
      />
      {!isMobile && (
        <Box sx={{ display: 'flex' }}>
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ height: 40 }}
          />
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ height: 40 }}
          />
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ height: 40 }}
          />
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ height: 40 }}
          />
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ height: 40 }}
          />
        </Box>
      )}
    </Box>
  );
};
