import { Box, Typography } from '@mui/material';
import logo from '../../../../../../assets/icons/logo.png';
import { Suggestion } from '../Suggestion/Suggestion.component';
import { useResponsive } from '../../../../../../hooks/useResponsive';

export const GreetingMessage = () => {
  const { isMobile } = useResponsive();
  const iconSize = `${isMobile ? '32px' : '56px'}`;
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: '16px',
        borderRadius: '16px',
        backgroundColor: 'white',
        padding: '24px 16px',
        marginBottom: '24px',
      }}
    >
      <Box>
        <img width={iconSize} height={iconSize} src={logo} alt="logo" />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Typography>
            Hello! I`m your AI assistant. Ready to find your luxury London home?
            Which neighbourhood are you interested in? What`s your vision of a
            perfect home?
            <br /> I`m here to help you find homes that match your vision
            perfectly, faster than ever before.
          </Typography>
          <Typography sx={{ paddingTop: '16px' }}>Let`s begin!</Typography>
        </Box>

        <Box
          display="grid"
          gap={2}
          sx={{
            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
            paddingTop: '16px',
          }}
        >
          <Suggestion text="Spacious 4 bedroom apartment in Mayfair, with high ceilings and modern amenities" />
          <Suggestion text="Exceptional, large 6 bedroom house modernised to highest standarts in central London" />
          <Suggestion text="Off market, unlisted homes in Belgravia and Knightsbridge at budget levels of £20m or above" />
          <Suggestion text="3-4 bedroom apartment around Regent`s Park area with outdoor terrace, 24x7 porter, and car park" />
        </Box>
      </Box>
    </Box>
  );
};
