export const getTitle = (propertyData) => {
  if (propertyData.first_address) {
    return `${propertyData.first_address}`;
  } else if (propertyData.second_address) {
    return propertyData.second_address;
  } else {
    return propertyData.id;
  }
};

export const isJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
