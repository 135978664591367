export const storeToken = (token) => {
  const encodedToken = btoa(token);
  localStorage.setItem('token', encodedToken);
};

export const retrieveToken = () => {
  const encodedToken = localStorage.getItem('token');
  if (encodedToken) {
    return atob(encodedToken);
  }
  return null;
};
