import { ChangePassword } from '../ProfileSettings/components/ChangePassword/ChangePassword.component';
import { ChatContent } from '../ChatContent/ChatContent.component';
import { ProfileSettings } from '../ProfileSettings/ProfileSettings.component';
import { SettingsPasswordRecovery } from '../ProfileSettings/components/SettingsPasswordRecovery/SettingsPasswordRecovery.component';
import { SettingsCheckEmail } from '../ProfileSettings/components/SettingsCheckEmail/SettingsCheckEmail.component';
import { FavoriteProperty } from '../FavoriteProperty/FavoriteProperty.component';
import { Greeting } from '../../pages/Greeting/Greeting.page';

export const mainLayoutRoutesConfig = [
  {
    index: true,
    element: <Greeting />,
  },
  {
    path: 'profile-settings',
    element: <ProfileSettings />,
  },
  {
    path: 'change-password',
    element: <ChangePassword />,
  },
  {
    path: 'conversation/:conversationId',
    element: <ChatContent />,
  },
  {
    path: 'favorite/:favoriteId',
    element: <FavoriteProperty />,
  },
  {
    path: 'settings-recovery-password',
    element: <SettingsPasswordRecovery />,
  },
  {
    path: 'settings-check-email',
    element: <SettingsCheckEmail />,
  },
];
