import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { HeaderWithLogo } from '../../../../../HeaderWithLogo/HeaderWithLogo.component';
import { ChangePasswordForm } from '../ChangePasswordForm/ChangePasswordForm.component';

export const ChangePasswordModal = ({
  open,
  handleClose,
  handleRecoveryOpen,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <HeaderWithLogo title="Change password" />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ChangePasswordForm
          handleClose={handleClose}
          handleRecoveryOpen={handleRecoveryOpen}
        />
      </DialogContent>
    </Dialog>
  );
};
