import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './SignUpForm.utils';
import { useMutation } from 'react-query';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useShowPassword } from '../../../../../../hooks/FormHooks/useShowPassword.hook';
import { useToast } from '../../../../../../hooks/UseToast';
import { WhiteBackgroundTextField } from '../../../../../../components/WhiteBackgroundTextField/WhiteBackgroundTextField';
import { authService } from '../../../../../../api/AuthService';
import { PrivacyPolicy } from '../../../../../../components/PrivacyPolicy&TermsOfUse/components/PrivacyPolicy/PrivacyPolicy.component';
import { TermsAndConditions } from '../../../../../../components/PrivacyPolicy&TermsOfUse/components/TermsAndConditions/TermsAndConditions.component';
import { useState } from 'react';
import { PrimaryButton } from '../../../../../../components/PrimaryButton/PrimaryButton.component';

export const SignUpForm = ({ handleOpenCheckEmailAfterSignUp }) => {
  const [
    showPassword,
    handlePasswordVisibility,
    showConfirmPassword,
    handleConfirmPasswordVisibility,
  ] = useShowPassword();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const [privatPolicyOpen, setPrivatPolicyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const openTerms = () => {
    setTermsOpen(true);
  };
  const closeTerms = () => setTermsOpen(false);

  const openPrivatePolicy = () => {
    setPrivatPolicyOpen(true);
  };
  const closePrivacyPolicy = () => setPrivatPolicyOpen(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      mutation.mutate({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
      });
      localStorage.setItem('email', values.email);
      resetForm();
    },
  });

  const mutation = useMutation(authService.signUp, {
    onSuccess: (data) => {
      handleOpenCheckEmailAfterSignUp
        ? handleOpenCheckEmailAfterSignUp()
        : navigate('/email-confirm');
    },
    onError: (error) => {
      console.log(error);
      showErrorToast(error.response.data.email[0]);
    },
  });

  const isDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

  return (
    <Box>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <WhiteBackgroundTextField
          required
          fullWidth
          id="first-name"
          label="First name"
          name="first_name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
          autoFocus
        />
        <WhiteBackgroundTextField
          required
          fullWidth
          id="last-name"
          label="Last name"
          name="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
        />
        <WhiteBackgroundTextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <WhiteBackgroundTextField
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          autoComplete="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handlePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <WhiteBackgroundTextField
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          id="confirm-password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          autoComplete="password"
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleConfirmPasswordVisibility}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex" alignItems="center">
          <FormControlLabel
            sx={{ paddingBottom: '20px', marginRight: 0 }}
            control={
              <Checkbox
                name="termsAndConditions"
                color="primary"
                checked={formik.values.termsAndConditions}
                onChange={formik.handleChange}
              />
            }
          />
          <Typography sx={{ paddingBottom: '20px', textAlign: 'center' }}>
            I accept{' '}
            <Box
              component={'span'}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={openTerms}
            >
              Terms and Conditions
            </Box>{' '}
            and{' '}
            <Box
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={openPrivatePolicy}
              component={'span'}
            >
              Privacy policy
            </Box>
          </Typography>
        </Box>

        <PrimaryButton
          label=" Create"
          size="large"
          disabled={isDisabled}
        ></PrimaryButton>
      </form>
      <PrivacyPolicy open={privatPolicyOpen} handleClose={closePrivacyPolicy} />
      <TermsAndConditions open={termsOpen} handleClose={closeTerms} />
    </Box>
  );
};
