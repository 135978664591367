import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { paragraphSx, titleSx } from '../PrivacyPolicy/PrivacyPolicy.style';
import { MainLabel } from '../../../MainLabel/MainLabel.component';

export const TermsAndConditions = ({ open, handleClose }) => {
  return (
    <Dialog open={open} fullWidth maxWidth={'lg'}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Box>
            <MainLabel />
          </Box>
          Terms of Service
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={titleSx}>Introduction</Typography>
        <Typography sx={paragraphSx}>
          Welcome to SuperPrime Bot! These terms of service (“Terms”) govern
          your use of the SuperPrime Bot service, an AI-driven property search
          platform.
        </Typography>
        <Typography sx={titleSx}>Acceptance of Terms</Typography>
        <Typography sx={paragraphSx}>
          By accessing or using our service, you agree to be bound by these
          Terms. If you disagree with any part of the terms, you may not access
          the service.
        </Typography>
        <Typography sx={titleSx}>Service Description</Typography>
        <Typography sx={paragraphSx}>
          SuperPrime Bot is an AI-based property search tool designed to assist
          users in finding properties in London. It provides personalized
          property recommendations based on user preferences.
        </Typography>{' '}
        <Typography sx={titleSx}>Use of Service</Typography>
        <Typography sx={paragraphSx}>
          • Account Registration: You must register for an account to use
          SuperPrime Bot’s full features. <br />• User Responsibilities: You are
          responsible for maintaining the confidentiality of your account and
          password. <br />• Acceptable Use: You agree not to use the service for
          any unlawful purpose or in any way that interrupts, damages, or
          impairs the service.
        </Typography>
        <Typography sx={titleSx}>Modifications to Service</Typography>
        <Typography sx={paragraphSx}>
          SuperPrime Bot reserves the right to modify or discontinue,
          temporarily or permanently, the service (or any part thereof) with or
          without notice.
        </Typography>
        <Typography sx={titleSx}>Fees and Payment</Typography>
        <Typography sx={paragraphSx}>
          {' '}
          • Free Service: The service is currently provided free of charge.{' '}
          <br />• Future Charges: We reserve the right to introduce charges for
          the service or any part thereof in the future, at our discretion.
        </Typography>
        <Typography sx={titleSx}>Intellectual Property</Typography>
        <Typography sx={paragraphSx}>
          All content included on the service, such as text, graphics, logos,
          images, as well as the compilation thereof, and any software used on
          the service, is the property of SuperPrime Bot or its licensors and
          protected by copyright and other laws.
        </Typography>
        <Typography sx={titleSx}>User Data</Typography>
        <Typography sx={paragraphSx}>
          • Data Collection: We collect and use your data in accordance with our
          Privacy Policy. <br /> • User Consent: By using the service, you
          consent to the collection, use, and sharing of your data as outlined
          in the Privacy Policy.
        </Typography>
        <Typography sx={titleSx}>Disclaimer of Warranties</Typography>
        <Typography sx={paragraphSx}>
          The service is provided “as is” and “as available” without any
          warranties, express or implied, including but not limited to the
          implied warranties of merchantability, fitness for a particular
          purpose, or non-infringement.
        </Typography>
        <Typography sx={titleSx}>Limitation of Liability</Typography>
        <Typography sx={paragraphSx}>
          SuperPrime Bot shall not be liable for any indirect, incidental,
          special, consequential, or punitive damages resulting from your access
          to or use of, or inability to access or use the service.
        </Typography>
        <Typography sx={titleSx}>Changes to Terms</Typography>
        <Typography sx={paragraphSx}>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. By continuing to access or use our service
          after those revisions become effective, you agree to be bound by the
          revised terms.
        </Typography>
        <Typography sx={titleSx}>Governing Law</Typography>
        <Typography sx={paragraphSx}>
          These Terms shall be governed by and construed in accordance with the
          laws of the United Kingdom.
        </Typography>
        <Typography sx={titleSx}>Contact Information</Typography>
        <Typography sx={paragraphSx}>
          For any questions about these Terms, please contact us at
          <a
            href="mailto:hello@homehapp.ai"
            style={{ textDecoration: 'none', paddingLeft: '4px' }}
          >
            hello@homehapp.ai
          </a>
          .
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
