import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

import { PrivacyPolicyAndTermsOfUse } from '../../../components/PrivacyPolicy&TermsOfUse/PrivacyPolicy&TermsOfUse.component';
import { useResponsive } from '../../../hooks/useResponsive';
import { HeaderWithLogo } from '../../../components/HeaderWithLogo/HeaderWithLogo.component';
import { LoginForm } from './components/LoginForm/LoginForm.component';
import { containerSx } from '../../SignUp/components/SignUpFormWrapper/SignUpForm.style';

export const LoginFormWrapper = () => {
  const { isMobile } = useResponsive();

  return (
    <>
      <Box
        sx={{
          padding: '0px 70px',
          display: `${isMobile ? 'none' : 'grid'}`,
          gridTemplateRows: '1fr auto',
          height: '100vh',
          alignItems: 'center',
        }}
      >
        <Container sx={containerSx}>
          <HeaderWithLogo title="Login into SuperPrime.Bot">
            <Box
              sx={{ padding: '8px 0 24px' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="0.75rem"
            >
              <Typography sx={{ fontSize: '1rem' }} variant="body2">
                Don't have an account?
              </Typography>
              <Link
                href="/signup"
                underline="none"
                variant="body2"
                pl={1}
                sx={{ fontWeight: 700, fontSize: '1rem' }}
              >
                Create account
              </Link>
            </Box>
          </HeaderWithLogo>

          <LoginForm />
        </Container>
        <PrivacyPolicyAndTermsOfUse />
      </Box>
    </>
  );
};
