import { axiosInstance } from './api';

class PropertyService {
  async addToFavorite(propertyData) {
    const { data } = await axiosInstance.post(
      `properties/favorite/`,
      propertyData
    );
    return data;
  }

  async removeFromFavorites(id) {
    const { data } = await axiosInstance.delete(`properties/favorite/${id}/`);
    return data;
  }

  async fetchFavorites() {
    const { data } = await axiosInstance.get('properties/favorite/');
    return data;
  }

  async fetchFavoriteProperty(id) {
    const { data } = await axiosInstance.get(`properties/favorite/${id}/`);
    return data;
  }
}

export const propertyService = new PropertyService();
