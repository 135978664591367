import { Link } from '@mui/material';
import { PropertyValueContainer } from '../PropertyValueContainer/PropertyValueContainer.component';

export const PropertyLink = ({ link }) => {
  return (
    <PropertyValueContainer title={'Property Link'}>
      <Link
        sx={{ cursor: 'pointer' }}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link}
      </Link>
    </PropertyValueContainer>
  );
};
