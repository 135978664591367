import * as Yup from 'yup';

export const validationSchema = Yup.object({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'Accepting Terms and Conditions is required'
  ),
});

export const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  termsAndConditions: false,
};
