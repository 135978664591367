import { Box } from '@mui/material';
import { MainContentContainerSx } from './MainContentContainer.style';
import { Route, Routes } from 'react-router-dom';
import { mainLayoutRoutesConfig } from '../LayoutResolver/MainLayoutRoutesConfig';
import { useResponsive } from '../../hooks/useResponsive';

export const MainContentContainer = () => {
  const { isMobile } = useResponsive();
  return (
    <Box
      sx={{
        ...MainContentContainerSx,
        borderRadius: `${isMobile ? '16px' : '26px'}`,
      }}
      style={{ padding: `${isMobile ? '12px' : '24px'}` }}
    >
      <Routes>
        {mainLayoutRoutesConfig.map((route) => (
          <Route key={route.path || 'index'} {...route} />
        ))}
      </Routes>
    </Box>
  );
};
