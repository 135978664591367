import { Box } from '@mui/material';
import { Intro } from '../Intro/Intro.component';
import { useResponsive } from '../../hooks/useResponsive';

export const AuthContainer = ({ children }) => {
  const { isMobile } = useResponsive();
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${!isMobile ? '57fr 43fr' : '100%'}`,
        height: '100vh',
      }}
    >
      <Intro />
      {children}
    </Box>
  );
};
