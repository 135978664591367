import { Drawer } from '@mui/material';
import { Sidebar } from '../Sidebar/Sidebar.component';

export const MobileSidebar = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: 'calc(100% - 94px)',
          top: 94,
          fontFamily: 'Roboto',
        },
      }}
    >
      <Sidebar closeDrawerHandler={onClose} />
    </Drawer>
  );
};
