export const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  gap: '1.125rem',
  width: '100%',
};

export const createAccBtnSx = {
  borderRadius: '8px',
  padding: '15px 30px',
  color: '#003366',
  fontSize: '1.125rem',
  fontWeight: '600',
};

export const loginBtnSx = {
  borderRadius: '8px',
  padding: '15px 30px',
  backgroundColor: ' #F0A400',
  color: '#003366',
  fontSize: '1.125rem',
  fontWeight: '600',
};
