import { useRef } from 'react';

export const useAudioRecorder = (onDataAvailable, onError, setIsRecording) => {
  const recordedData = useRef([]);
  const recordingLength = useRef(0);
  const audioCtx = useRef(null);
  const audioInput = useRef(null);
  const audioNode = useRef(null);
  const localStream = useRef(null);
  const sampleRate = useRef(null);

  const handleStartRecording = async () => {
    try {
      recordedData.current = [];
      recordingLength.current = 0;

      const AudioContext = window.AudioContext || window.webkitAudioContext;
      if (!AudioContext) {
        throw new Error('Web Audio API not supported in this browser');
      }

      audioCtx.current = new AudioContext();

      if (audioCtx.current.createScriptProcessor) {
        audioNode.current = audioCtx.current.createScriptProcessor(4096, 1, 1);
      } else if (audioCtx.current.createJavaScriptNode) {
        audioNode.current = audioCtx.current.createJavaScriptNode(4096, 1, 1);
      } else {
        throw new Error('WebAudio not supported!');
      }

      localStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      audioInput.current = audioCtx.current.createMediaStreamSource(
        localStream.current
      );
      audioInput.current.connect(audioNode.current);
      audioNode.current.connect(audioCtx.current.destination);
      audioNode.current.onaudioprocess = onAudioProcess;
      sampleRate.current = audioCtx.current.sampleRate;

      if (setIsRecording) setIsRecording(true);
    } catch (error) {
      if (onError) onError(error);
      console.error('Error accessing the microphone:', error);
    }
  };

  const handleStopRecording = () => {
    if (audioCtx.current) {
      localStream.current.getTracks().forEach((track) => track.stop());
      audioInput.current.disconnect();
      audioNode.current.disconnect();
      exportWav(
        {
          sampleRate: sampleRate.current,
          recordingLength: recordingLength.current,
          data: recordedData.current,
        },
        (blob) => {
          if (onDataAvailable) onDataAvailable(blob);
        }
      );
      audioCtx.current.close().then(() => {
        audioCtx.current = null;
        if (setIsRecording) setIsRecording(false);
      });
    } else {
      console.error('audioCtx is undefined or has been cleared.');
    }
  };

  const onAudioProcess = (event) => {
    if (audioCtx.current) {
      const channelData = new Float32Array(event.inputBuffer.getChannelData(0));
      recordedData.current.push(channelData);
      recordingLength.current += 4096;
    }
  };

  const exportWav = (config, callback) => {
    const data = joinBuffers(config.data, config.recordingLength);
    const buffer = new ArrayBuffer(44 + data.length * 2);
    const view = new DataView(buffer);

    writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, 44 + data.length * 2, true);
    writeUTFBytes(view, 8, 'WAVE');
    writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true);
    view.setUint32(24, config.sampleRate, true);
    view.setUint32(28, config.sampleRate * 2, true);
    view.setUint16(32, 2, true);
    view.setUint16(34, 16, true);
    writeUTFBytes(view, 36, 'data');
    view.setUint32(40, data.length * 2, true);

    let index = 44;
    for (let i = 0; i < data.length; i++) {
      view.setInt16(index, data[i] * 0x7fff, true);
      index += 2;
    }

    const blob = new Blob([view], { type: 'audio/wav' });
    callback(blob);
  };

  const joinBuffers = (channelBuffer, count) => {
    const result = new Float32Array(count);
    let offset = 0;

    for (let i = 0; i < channelBuffer.length; i++) {
      result.set(channelBuffer[i], offset);
      offset += channelBuffer[i].length;
    }

    return result;
  };

  const writeUTFBytes = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  return {
    startRecording: handleStartRecording,
    stopRecording: handleStopRecording,
  };
};
