import { Box, Container } from '@mui/material';
import { HeaderWithLogo } from '../../../HeaderWithLogo/HeaderWithLogo.component';
import { useResponsive } from '../../../../hooks/useResponsive';
import { ChangePasswordForm } from './components/ChangePasswordForm/ChangePasswordForm.component';

export const ChangePassword = () => {
  const { isMobile } = useResponsive();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: `${isMobile ? 'none' : 'grid'}`,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container sx={{ padding: '60px', width: '540px' }}>
        <HeaderWithLogo title="Change password" />
        <ChangePasswordForm />
      </Container>
    </Box>
  );
};
