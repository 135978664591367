import { Box } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { feedbackContainerSx } from './Feedback.style';
import { IconCircleContainer } from './components/IconCircleContainer/IconCircleContainer.component';

export const Feedback = ({ handleFeedbackClick }) => {
  return (
    <Box sx={feedbackContainerSx}>
      <IconCircleContainer clickHandler={() => handleFeedbackClick(1)}>
        <ThumbUpIcon
          sx={{
            color: '#6B6B6B',
          }}
        />
      </IconCircleContainer>
      <IconCircleContainer clickHandler={() => handleFeedbackClick(-1)}>
        <ThumbDownIcon
          sx={{
            color: '#6B6B6B',
          }}
        />
      </IconCircleContainer>
    </Box>
  );
};
