import { Box, Button, Divider, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { WhiteBackgroundTextField } from '../WhiteBackgroundTextField/WhiteBackgroundTextField';
import { UserIcon } from '../UserIcon/UserIcon.component';
import lock from '../../assets/icons/lock.png';
import logoutIcon from '../../assets/icons/logout.png';
import {
  initialValues,
  validationSchema,
} from '../ProfileSettings/ProfileSettings.utils';
import { useResponsive } from '../../hooks/useResponsive';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordModal } from './components/ChangePassword/components/ChangePasswordModal/ChangePasswordModal.component';
import { PasswordRecoveryModal } from '../PasswordRecovery/components/PasswordRecoveryModal/PasswordRecoveryModal.component';
import { CheckEmailModal } from '../CheckEmail/CheckEmailModal/CheckEmailModal';
import { centeredFlexSx } from '../../layouts/MainContentLayout/MainContent.style';
import { useProfile } from '../../context/profileContext';
import { useMutation, useQueryClient } from 'react-query';
import { isEqual } from 'lodash';
import { useToast } from '../../hooks/UseToast';
import { profileService } from '../../api/ProfileService';
import { authService } from '../../api/AuthService';

export const ProfileSettings = () => {
  const { isMobile } = useResponsive();
  const [lastSubmittedValues, setLastSubmittedValues] = useState(initialValues);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isRecoveryPasswordModalOpen, setIsRecoveryPasswordModalOpen] =
    useState(false);
  const [isCheckEmailOpen, setIsCheckEmailOpen] = useState(false);
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { showSuccessToast, showErrorToast } = useToast();

  const queryClient = useQueryClient();
  const mutation = useMutation(profileService.updateProfile, {
    onSuccess: () => {
      showSuccessToast('Profile data was successfully updated');
      setLastSubmittedValues(formik.values);
      queryClient.invalidateQueries('profile');
    },
    onError: () => showErrorToast(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (isEqual(values, lastSubmittedValues)) {
        return;
      }
      mutation.mutate(values);
    },
  });

  useEffect(() => {
    if (profile) {
      formik.resetForm({
        values: {
          first_name: profile.first_name || '',
          last_name: profile.last_name || '',
          phone_number: profile.phone_number || '',
        },
      });
    }
  }, [profile, formik.resetForm]);

  const logoutHandler = () => {
    authService.logout().then(() => {
      localStorage.removeItem('token');
      navigate('/login');
    });
  };

  const goBackHandler = () => navigate(-1);

  const closeChangePasswordModal = () => setIsChangePasswordModalOpen(false);

  const handleOpenChangePasswordModal = () => {
    if (isMobile) {
      setIsChangePasswordModalOpen(true);
    } else {
      navigate('/change-password');
    }
  };

  const handleOpenPasswordRecoveryModal = () => {
    setIsRecoveryPasswordModalOpen(true);
    setIsChangePasswordModalOpen(false);
  };

  const handleClosePasswordRecoveryModal = () => {
    setIsRecoveryPasswordModalOpen(false);
  };

  const handleOpenCheckEmailModal = () => {
    setIsCheckEmailOpen(true);
    handleClosePasswordRecoveryModal();
  };

  const handleCloseCheckEmailModal = () => {
    setIsCheckEmailOpen(false);
  };

  return (
    <Box
      sx={{
        ...centeredFlexSx,
        padding: `${isMobile ? '0px' : '24px'}`,
        height: '100%',
      }}
    >
      <Box
        sx={{ padding: `${isMobile ? '0px' : '90px 0'}`, maxWidth: '420px' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ paddingBottom: '24px' }}
        >
          {profile && (
            <UserIcon username={profile.first_name} height={56} width={56} />
          )}
          <Typography mt={2} sx={{ fontWeight: 500, fontSize: 24 }}>
            Your SuperPrime.Bot account
          </Typography>
        </Box>

        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <WhiteBackgroundTextField
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={profile?.email || ''}
            onChange={formik.handleChange}
            disabled={true}
          />
          <WhiteBackgroundTextField
            fullWidth
            id="first_name"
            label="First name"
            name="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
            autoFocus
          />
          <WhiteBackgroundTextField
            fullWidth
            id="last_name"
            label="Last name"
            name="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />
          <WhiteBackgroundTextField
            fullWidth
            id="phone_number"
            label="Phone number"
            name="phone_number"
            autoComplete="email"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            error={
              formik.touched.phone_number && Boolean(formik.errors.phone_number)
            }
            helperText={
              formik.touched.phone_number && formik.errors.phone_number
            }
          />
          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={goBackHandler}
              sx={{ color: '#6B6B6B', width: '50%' }}
              variant="text"
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                width: '50%',
                color: '#1A365D',
                '&:hover': { backgroundColor: '#FF9407' },
              }}
            >
              Continue
            </Button>
          </Box>
        </form>
        <Divider sx={{ margin: '16px 0px' }} />
        <Box
          onClick={handleOpenChangePasswordModal}
          display="flex"
          sx={{ paddingBottom: '24px', '&:hover': { cursor: 'pointer' } }}
        >
          <img width="24" height="24px" src={lock} alt="lock-icon" />
          <Typography color="#6B6B6B" sx={{ paddingLeft: '8px' }}>
            Change Password
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={{ paddingBottom: '24px', '&:hover': { cursor: 'pointer' } }}
          onClick={logoutHandler}
        >
          <img width="24" height="24px" src={logoutIcon} alt="logout-icon" />
          <Typography color="#6B6B6B" sx={{ paddingLeft: '8px' }}>
            Logout
          </Typography>
        </Box>
      </Box>
      <ChangePasswordModal
        open={isChangePasswordModalOpen}
        handleClose={closeChangePasswordModal}
        handleRecoveryOpen={handleOpenPasswordRecoveryModal}
      />
      <PasswordRecoveryModal
        open={isRecoveryPasswordModalOpen}
        handleClose={handleClosePasswordRecoveryModal}
        handleOpenCheckEmail={handleOpenCheckEmailModal}
      />
      <CheckEmailModal
        open={isCheckEmailOpen}
        handleClose={handleCloseCheckEmailModal}
      />
    </Box>
  );
};
