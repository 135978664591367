import { Box, Button } from '@mui/material';
import { PropertyTitle } from './components/PropertyTitle/PropertyTitle.component';
import { PropertyDescription } from './components/PropertyDescription/PropertyDescription.component';
import { PropertyLink } from './components/PropertyLink/PropertyLink.component';
import { PropertyContactInfo } from './components/PropertyContactInfo/PropertyContactInfo.component';
import { useResponsive } from '../../hooks/useResponsive';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { propertyService } from '../../api/PropertyService';
import { useToast } from '../../hooks/UseToast';
import { useEffect } from 'react';
import { getTitle } from '../../utils/utils';
import { btnSx } from './FavoriteProperty.style';

export const FavoriteProperty = () => {
  const { favoriteId } = useParams();
  const { showErrorToast } = useToast();
  const { data: favoritePropertyData } = useQuery(
    ['property', favoriteId],
    () => propertyService.fetchFavoriteProperty(favoriteId),
    {
      onError: (error) => {
        showErrorToast();
      },
    }
  );

  const contactInfo = {
    fullName: favoritePropertyData?.property?.contact_full_name,
    phone: favoritePropertyData?.property?.contact_phone,
    email: favoritePropertyData?.property?.contact_email,
  };

  const hasValidContactInfo = Object.values(contactInfo).some(
    (value) => value !== ''
  );

  useEffect(() => {}, [favoriteId]);

  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Box>
        <Button
          onClick={() => navigate(-1)}
          sx={btnSx}
          startIcon={<KeyboardArrowLeftIcon />}
        >
          Back
        </Button>
      </Box>
      <Box
        sx={{
          padding: `${isMobile ? '16px 12px' : '40px'}`,
          backgroundColor: '#FFFFFF',
          borderRadius: '16px',
          overflow: 'scroll',
          height: `${isMobile ? '57vh' : '65vh'}`,
        }}
      >
        {favoritePropertyData && (
          <>
            <PropertyTitle
              link={favoritePropertyData?.property?.property_url}
              title={getTitle(favoritePropertyData.property)}
              id={favoritePropertyData.id}
            />
            {favoritePropertyData.description && (
              <PropertyDescription
                description={favoritePropertyData.description}
              />
            )}
            {favoritePropertyData?.property?.property_url && (
              <PropertyLink
                link={favoritePropertyData?.property?.property_url}
              />
            )}
            {hasValidContactInfo && (
              <PropertyContactInfo contactData={contactInfo} />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
