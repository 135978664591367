import { Box, Typography } from '@mui/material';
import { FavoriteIconBtn } from '../../../FavoriteIconBtn/FavoriteIconBtn.component';
import { ShareByEmailBtn } from '../../../ShareByEmailBtn/ShareByEmailBtn.component';
import { useNavigate } from 'react-router-dom';
import { propertyService } from '../../../../api/PropertyService';
import { useQuery } from 'react-query';
import { useToast } from '../../../../hooks/UseToast';
import { useConversationsList } from '../../../../context/conversationsContext';

export const PropertyTitle = ({ title, link, id }) => {
  const { showErrorToast } = useToast();
  const { conversations } = useConversationsList();
  const lastConversationId = conversations.length ? conversations[0].id : null;
  const navigate = useNavigate();

  const { refetch: refetchFavorites } = useQuery(
    'favorites',
    propertyService.fetchFavorites,
    {
      onError: (error) => {
        showErrorToast();
      },
    }
  );

  const removeFromFavoritesHandler = () => {
    propertyService.removeFromFavorites(id).then(() => {
      refetchFavorites();
      navigate(`/conversation/${lastConversationId}`);
    });
  };
  return (
    <Box display="flex" flexDirection="column" gap="16px" paddingBottom="24px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="24px" fontWeight="500">
          {title}
        </Typography>
        <FavoriteIconBtn
          clickHandler={removeFromFavoritesHandler}
          isFavorite={true}
        />
      </Box>
      <Box display="flex" alignItems="center" gap="16px">
        <Typography>{`ID: ${id}`}</Typography>
        <ShareByEmailBtn
          url={link}
          subject={'Property'}
          body={'Hello I want to share the link '}
        />
      </Box>
    </Box>
  );
};
