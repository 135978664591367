import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { authService } from '../../api/AuthService';
import { useToast } from '../../hooks/UseToast';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton.component';

export const EmailConfirmation = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const { showSuccessToast, showErrorToast } = useToast();
  const confirmEmailHandler = () => {
    authService
      .confirmEmail(uid, token)
      .then(() => {
        showSuccessToast('Email was successfully confirmed');
        navigate('/login');
      })
      .catch(() => showErrorToast());
  };

  return (
    <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
      <PrimaryButton
        onClick={confirmEmailHandler}
        sx={{ width: '172px' }}
        label="Confirm Email"
      />
    </Box>
  );
};
