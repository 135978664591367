import { Navbar } from '../../components/Navbar/Navbar.component';
import { Sidebar } from '../../components/Sidebar/Sidebar.component';
import { Box } from '@mui/material';
import { MainContentContainer } from '../../components/MainContentContainer/MainContentContainer.component';
import { MobileSidebar } from '../../components/MobileSidebar/MobileSidebar.component';
import { useResponsive } from '../../hooks/useResponsive';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useProfile } from '../../context/profileContext';
import { profileService } from '../../api/ProfileService';

export const MainContentLayout = () => {
  const { isMobile } = useResponsive();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleOpenDrawer = () => setIsDrawerOpen(true);
  const handleCloseDrawer = () => setIsDrawerOpen(false);
  const { data: userProfileData } = useQuery(
    'profile',
    profileService.fetchProfile
  );
  const { setProfile } = useProfile();

  useEffect(() => {
    setProfile(userProfileData);
  }, [userProfileData, setProfile]);

  return (
    <Box sx={{ height: `${isMobile ? '80vh' : '100vh'}` }}>
      <Navbar handleOpenDrawer={handleOpenDrawer} />
      <Box
        sx={{
          gridTemplateColumns: `${isMobile ? '1fr' : '267px 1fr'}`,
          display: `${isMobile ? 'flex' : 'grid'}`,
        }}
      >
        {!isMobile && <Sidebar />}
        <MobileSidebar open={isDrawerOpen} onClose={handleCloseDrawer} />
        <Box
          sx={{
            height: `${
              isMobile ? 'calc(100vh - 123px)' : 'calc(100vh - 73px)'
            }`,
            padding: `${isMobile ? '10px 10px 0px' : '0 40px 0px 0'}`,
            width: `${isMobile ? '100%' : 'auto'}`,
          }}
        >
          <MainContentContainer />
        </Box>
      </Box>
    </Box>
  );
};
