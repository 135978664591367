import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderWithLogo } from '../../HeaderWithLogo/HeaderWithLogo.component';

export const CheckEmailModal = ({ open, handleClose }) => {
  const email = localStorage.getItem('email');
  const backHandler = () => {
    localStorage.removeItem('email');
    handleClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        <HeaderWithLogo title="Check Your Email" />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography textAlign="center" sx={{ marginBottom: '32px' }}>
          Please check the email address{' '}
          <Typography component="span" sx={{ fontWeight: '600' }}>
            {email}
          </Typography>{' '}
          for instructions to reset your password.
        </Typography>
        <Button
          onClick={backHandler}
          fullWidth
          variant="contained"
          color="secondary"
          size="large"
        >
          Back
        </Button>
      </DialogContent>
    </Dialog>
  );
};
