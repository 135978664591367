import { useFormik } from 'formik';
import { WhiteBackgroundTextField } from '../../../../../WhiteBackgroundTextField/WhiteBackgroundTextField';
import { useNavigate } from 'react-router-dom';
import { useShowPassword } from '../../../../../../hooks/FormHooks/useShowPassword.hook';
import { initialValues, validationSchema } from './ChangePasswordForm.utils';
import { Box, Button, IconButton, InputAdornment, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useToast } from '../../../../../../hooks/UseToast';
import { authService } from '../../../../../../api/AuthService';

export const ChangePasswordForm = ({ handleRecoveryOpen, handleClose }) => {
  const navigate = useNavigate();
  const [
    showPassword,
    handlePasswordVisibility,
    showConfirmPassword,
    handleConfirmPasswordVisibility,
  ] = useShowPassword();
  const { showErrorToast, showSuccessToast } = useToast();

  const goBackHandler = () => navigate(-1);

  const handleForgotPassword = () => {
    handleRecoveryOpen
      ? handleRecoveryOpen()
      : navigate('/settings-recovery-password');
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      authService
        .changePassword(values)
        .then((response) => {
          handleClose && handleClose();
          showSuccessToast('Password was succesfully changed');
          resetForm();
        })
        .catch((error) => {
          showErrorToast(error.response.data.new_password[0]);

          error.response.data.old_password[0] &&
            showErrorToast(error.response.data.old_password[0]);
        });
    },
  });
  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      style={{ paddingTop: '24px' }}
    >
      <WhiteBackgroundTextField
        required
        fullWidth
        name="old_password"
        label="Current password"
        type={showPassword ? 'text' : 'password'}
        id="old-password"
        value={formik.values.old_password}
        autoComplete="old_password"
        onChange={formik.handleChange}
        error={
          formik.touched.old_password && Boolean(formik.errors.old_password)
        }
        helperText={formik.touched.old_password && formik.errors.old_password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <WhiteBackgroundTextField
        required
        fullWidth
        name="new_password"
        label="New password"
        type={showConfirmPassword ? 'text' : 'password'}
        id="new-password"
        value={formik.values.new_password}
        autoComplete="new_password"
        onChange={formik.handleChange}
        error={
          formik.touched.new_password && Boolean(formik.errors.new_password)
        }
        helperText={formik.touched.new_password && formik.errors.new_password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleConfirmPasswordVisibility} edge="end">
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleForgotPassword}
        underline="none"
        variant="body2"
        py={2}
        color="primary"
      >
        Forgot password?
      </Link>

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ paddingTop: '24px' }}
      >
        <Button
          onClick={goBackHandler}
          sx={{ color: '#6B6B6B', width: '50%' }}
          variant="text"
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{
            width: '50%',
            '&:hover': { backgroundColor: '#FF9407' },
            color: '#1A365D',
          }}
          disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
        >
          Save
        </Button>
      </Box>
    </form>
  );
};
